import { createTheme, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import styled from 'styled-components'

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 745,
			md: 1075,
			lg: 1201,
			xl: 2500,
		},
	},
})
export const InformationrContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 660.95px)': {
			marginTop: theme.spacing(0),
			padding: theme.spacing(0),
			maxWidth: '684px',
			marginLeft: '0px',
			marginRight: '0px',
			marginBottom: theme.spacing(-3),
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			marginTop: theme.spacing(0),
			padding: theme.spacing(0),
			maxWidth: '684px',
			marginLeft: '30px',
			marginRight: '30px',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '1006px',
			marginLeft: '34px',
			marginRight: '34px',
		},
		[theme.breakpoints.only('md')]: {
			maxWidth: '1024px',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		[theme.breakpoints.only('lg')]: {
			maxWidth: '1120px',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		marginTop: theme.spacing(-12),
	},
})(Grid) as typeof Grid
export const ImageSectionContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 860px)': {
			maxWidth: '684px',
			flexBasis: '684px',
			maxHeight: '300px',
		},
	},
})(Grid) as typeof Grid
export const FormContainer = withStyles({
	root: {
		height: '565px',
		marginTop: theme.spacing(15),
		flexWrap: 'nowrap',
		marginBottom: 0,
		display: 'flex',
		'@media (min-width: 0px) and (max-width: 860.95px)': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			height: '906px',
		},
		'@media (min-width: 860.95px) and (max-width: 1074.95px)': {
			display: 'flex',
			flexDirection: 'row',
			marginTop: theme.spacing(10),
			paddingLeft: '50px',
			paddingRight: '50px',
		},
		[theme.breakpoints.only('lg')]: {
			maxHeight: '566.13px',
			marginTop: theme.spacing(17.5),
		},
		justifyContent: 'center',
		borderRadius: '7px',
	},
})(Grid) as typeof Grid

export const FormSectionContainer = withStyles({
	root: {
		maxWidth: '325px',
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(0),
		'@media (min-width: 0px) and (max-width: 860.95px)': {
			position: 'relative',
			marginTop: 0,
			paddingTop: 0,
			marginRight: '20px',
			marginLeft: '20px',
			marginBottom: theme.spacing(4),
		},
		'@media (min-width: 860.95px) and (max-width: 743.95px)': {
			maxWidth: '85%',
			flexBasis: '85%',
			display: 'flex',
			alignItems: 'flex-start',
			marginBottom: theme.spacing(4),
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '400px',
			alignItems: 'flex-start',
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})(Grid) as typeof Grid

export const FormImage = styled.img`
	position: relative;
	bottom: 110px;
	right: 120px;
	@media (min-width: 0px) and (max-width: 500px) {
		top: -139px;
		width: 100%;
		right: 0;
	}
	@media (min-width: 500px) and (max-width: 860.95px) {
		top: -139px;
		max-width: 450px;
		min-width: 200px;
		right: 0;
	}
	@media (min-width: 860.95px) and (max-width: 1074.95px) {
		top: -92px;
		right: 48%;
		width: 145%;
	}
	@media (min-width: 1074.95px) and (max-width: 1201px) {
		top: -120px;
		right: 35%;
		width: 120%;
	}
	@media (min-width: 1201px) and (max-width: 2500px) {
		top: -155px;
		right: 22%;
		width: 115%;
	}
	width: 600px;
`

export const TitleTypography = withStyles({
	root: {
		fontSize: '32px',
		'@media (min-width: 0px) and (max-width: 660px)': {
			fontSize: '24px',
			marginBottom: '16px',
		},
		marginBottom: '27px',
		lineHeight: 1.4,
		fontFamily: 'NotoSans',
		fontWeight: 400,
		maxWidth: '325px',
	},
})(Typography) as typeof Typography

export const RegisterFormSection = styled.form`
	margin-top: ${theme.spacing(3.5)}px;
	justify-content: center;
	display: contents;
`

export const ConditionTypography = styled.p<{
	fullWidth?: boolean
}>`
	color: #000;
	text-align: center;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: 0.15px;
	max-width: 315px;
	width: 89%;
	margin-top: 15.9px;
	${props => props.fullWidth && `
		width: 100%;
		max-width: 372px;
		font-size: 15px;
	`}
`

export const ConditionLink = styled.a<{
	decoration?: boolean
}>`
	color: rgba(21, 101, 192, 1);
	text-decoration: ${
	props => props.decoration ? 'underline' : 'none'
	};
	cursor: pointer;
`

import styled from "styled-components";
import { Grid } from '@material-ui/core';

export const Title = styled.h2`
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;

    @media (max-height: 768px) {
        font-size: 28px;
        margin-top: 30px;
    }

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

export const SubTitle = styled.p`

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #000000;
    width: 377px;
    margin-top: 10px;
    margin-bottom: 40px;

    @media (max-height: 768px) {
        font-size: 14px;
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`

export const Form = styled(Grid)`
    padding: 38px 43px 38px 43px;
    background: #FFFFFF;
    border-radius: 4px;
    max-width: 400px;
    max-height: 380px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    
    @media (max-height: 768px) {
        padding: 30px 30px 30px 30px;
        margin-bottom: 0px;
        width: 380px;
        height: 350px;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

`;
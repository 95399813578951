import { FC, useContext, useRef } from 'react';
import { ContainerCenter, RegisterContainer, TrialButton } from '../share/style';
import { UserContext } from '../context/UserContext';
import { UserContextType } from '../context/type';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useHistory } from 'react-router-dom';
import { SubTitle, Title } from '../components/register/style';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import "./styles/TempPassword.css"

interface Props {
	isNormalNavBar: Boolean
}

const TempPassword:FC<Props> = ({isNormalNavBar}) => {
    const history = useHistory();
	const { customTempPasswordAssigned } = useContext(UserContext) as UserContextType;
    const copyRef = useRef<HTMLSpanElement>(null);

    if(!customTempPasswordAssigned) {
        history.push({
            pathname: '/register',
        });
    }
    
	const handleTry = async () => {
        history.push({
            pathname: '/app-download',
        });
	}

    const copyContent = () => {
      if (copyRef.current) {
        const range = document.createRange();
        range.selectNode(copyRef.current);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);
        document.execCommand('copy');
        window.getSelection()?.removeRange(range);


        copyRef.current.classList.add('zoom');

        if (copyRef.current) {
            setTimeout(() => {
                copyRef.current!.style.boxShadow = 'none';
                copyRef.current!.classList.remove('zoom');
            }, 1000);
          }
      }
    };

    return (
        <RegisterContainer>
            <Header isNormalNavBar={isNormalNavBar} />
            <ContainerCenter style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '0px 20px 40px 20px',
                    backgroundColor: '#fff',
                    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)"
                }}>                
                
                <Title>
                    ¡Gracias por Registrarte!
                </Title>
                <SubTitle>
                    Favor copiar tu clave temporal, la cual sera necesaria para ingresar a la plataforma.
                </SubTitle>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '20px',
                    
                }}>
                    <span ref={copyRef}>
                        {customTempPasswordAssigned}
                    </span>
                    <FileCopyIcon  onClick={copyContent}  style={{
                        cursor: 'pointer',
                        color: '#3f51b5',
                    }}/>
                </div>
                <TrialButton
                            fullWidth
                            variant='contained'
                            color='primary'
                            size='small'
                            style={{
                                maxWidth: '315px',
                                marginTop: '20px',
                            }}
                            onClick={handleTry}
                        >
                            Continuar 
                        </TrialButton>
                        </div>

            </ContainerCenter>
            <Footer />
        </RegisterContainer>
    );
};

export default TempPassword;
import { FC } from 'react'
import { Backdrop, makeStyles, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { VideoButton } from '../../share/style'

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: 'rgba(61, 64, 67, 85%)',
	},
	label: {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '24px',
		lineHeight: '133.4%',
		textAlign: 'center',
		color: '#000000',
		width: '80%',
	},
	button: {
		backgroundColor: '#1976D2',
		color: '#FFFFFF',
	}
}))

interface Props {
	open: boolean
	handleClose: () => void
	children: React.ReactNode
	allowClickOutSide?: boolean
}
const VideoPlayer: FC<Props> = ({
	open,
	handleClose,
	children,
	allowClickOutSide = true,
}) => {
	const classes = useStyles()
	return (
		<Backdrop
			className={classes.backdrop}
			open={open}
			onClick={allowClickOutSide ? handleClose : undefined}
		>
			<VideoButton startIcon={<CloseIcon />} onClick={handleClose}>
				Cerrar
			</VideoButton>
			<div className='player-wrapper'>{children}</div>
		</Backdrop>
	)
}

export default VideoPlayer

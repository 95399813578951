import React, { FC, useRef } from 'react';
import {
  AIReportContainer,
  AIReportTitle,
  AISection,
  PrintButton,
  PrintContainer,
} from './styles';
import './AIDiagnosticReport.style.css';
import DiagnosticSection from './AIDiagnosticSection';
import { IADiagnostic } from '../../share/ai/types';
import PrintAIIcon from './PrintAIIcon';
import ReactToPrint from 'react-to-print';
import './AIDiagnosticReport.style.css'

interface props {
  diagnostics: IADiagnostic[];
}

const AIDiagnosticReport: FC<props> = ({ diagnostics }) => {
  const render = () => {
    return diagnostics.map((diagnostic: IADiagnostic, index: number) => <DiagnosticSection diagnostic={diagnostic} index={index} />);
  };
  const claimRef = useRef(null);

  return (
    <>
      <AIReportContainer ref={claimRef} className='AI_report__container'>
        <AISection>
          <AIReportTitle>Posibles diagnósticos</AIReportTitle>
          <ReactToPrint
            trigger={() => {
              return (
                <PrintContainer className="print-hide" >
                  <PrintAIIcon />
                  <PrintButton >imprimir</PrintButton>
                </PrintContainer>
              );
            }}
            content={() => claimRef.current}
          />
        </AISection>
        {render()}
      </AIReportContainer>
    </>
  );
};

export default AIDiagnosticReport;

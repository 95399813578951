import React, { FC, useContext, useState } from 'react';
import { HeaderContainer, LogoContainer, StyledTypography, WhatsappContainer } from './style';
import Logo from '../svg/Logo';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useHistory } from 'react-router-dom';
import { BorderButton, activeStyle, normal, shadowBox } from '../../share/style';
import VideoPlayer from '../videoPlayer/VideoPlayer';
import ReactPlayer from 'react-player';
import { MOBILE_SCREEN_WIDTH, URL_VIDEO } from '../../share/constant';
import {
	Divider,
	IconButton,
	List,
	ListItem,
	SwipeableDrawer,
	Theme,
	createStyles,
	makeStyles,
	useTheme,
} from '@material-ui/core';
import { NavigationContext } from '../../context/NavigationContext';
import { NavigationContextType, Tabs } from '../../context/type';
import MenuIcon from '@material-ui/icons/Menu';

import BrandName from '../svg/brandName';

import './Header.style.css';
interface Props {
	isNormalNavBar: Boolean;
}
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginTop: '100px',
			marginLeft: drawerWidth,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginLeft: -drawerWidth,
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		},
	})
);

const Header: FC<Props> = ({ isNormalNavBar }) => {
	const history = useHistory();
	const [open, setOpen] = useState<boolean>(false);
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false);
	const { activeTab, setActiveTab } = useContext(NavigationContext) as NavigationContextType;

	const openAndPlayVideo = () => {
		setOpen(true);
		setIsPlayingVideo(true);
	};

	const closeAndStopVideo = () => {
		setOpen(false);
		setIsPlayingVideo(false);
	};

	const handleOpenVideo = () => {
		openAndPlayVideo();
	};

	const goToAppPulseara = () => {
		window.location.href =` ${process.env.REACT_APP_APP_PULSEARA_URL}`
	};

	const onGoHome = () => {
		history.push('/');
		setActiveTab(Tabs.Home);
	};

	const goToRegister = () => {
		history.push('/register');
	};

	const goToAiPage = () => {
		history.push('/AI');
		setActiveTab(Tabs.AIDiagnostic);
	};

	const getNavItemStyles = (navItem: Tabs) => {
		return activeTab === navItem ? activeStyle : {};
	};

	const hideForMobile = () => {
		if (window.innerWidth < MOBILE_SCREEN_WIDTH) {
			return {
				display: 'none',
			};
		}
		return {
			display: 'flex',
		};
	};

	const isMobile = window.innerWidth <= MOBILE_SCREEN_WIDTH;

	const classes = useStyles();

	const handleDrawerOpen = () => {
		setOpenDrawer(!openDrawer);
	};

	return (
		<>
			{open && (
				<VideoPlayer open={open} handleClose={closeAndStopVideo}>
					<ReactPlayer
						className="react-player"
						url={URL_VIDEO}
						loop={true}
						playing={isPlayingVideo}
						controls={true}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						style={{ maxWidth: '870.3px' }}
						height={'501.2px'}
					/>
				</VideoPlayer>
			)}
			<HeaderContainer
				container
				style={isNormalNavBar && !isMobile ? normal : shadowBox}
				className="header-container"
			>
				<LogoContainer item xs={6} className="header-navbar">
					<Logo onClick={onGoHome} />
					<StyledTypography
						variant="h6"
						style={{
							...getNavItemStyles(Tabs.Home),
						}}
						onClick={onGoHome}
					>
						Inicio
					</StyledTypography>
					<StyledTypography
						variant="h6"
						style={{
							...getNavItemStyles(Tabs.AIDiagnostic),
						}}
						onClick={goToAiPage}
					>
						Diagnóstico con IA
					</StyledTypography>
				</LogoContainer>
				<LogoContainer className="header-navbar-desktop" container>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
					>
						<MenuIcon />
					</IconButton>
					<BrandName />
				</LogoContainer>
				<WhatsappContainer item xs={6}>
					<BorderButton
						fullWidth
						color="primary"
						size="medium"
						variant="text"
						startIcon={<PlayCircleFilledIcon />}
						onClick={handleOpenVideo}
						style={{
							width: '110px',
							margin: '0',
							padding: '4px 5px',
							fontSize: '13px',
							border: 'none',
						}}
					>
						Ver Demo
					</BorderButton>
					<BorderButton
						fullWidth
						color="primary"
						size="medium"
						variant="text"
						onClick={goToAppPulseara}
						style={{
							width: '120px',
							margin: '0',
							padding: '4px 5px',
							fontSize: '13px',
							border: 'none',
							letterSpacing: '0.46px',
							textTransform: 'uppercase',
						}}
						className="header-navbar__register_btn"
					>
						Iniciar sesión
					</BorderButton>
					<BorderButton
						fullWidth
						color="primary"
						size="medium"
						variant="outlined"
						startIcon={<PlayCircleFilledIcon />}
						onClick={goToRegister}
						style={{
							width: '200px',
							margin: '0',
							padding: '4px 10px',
							fontSize: '13px',
						}}
						className="header-navbar__register_btn"
					>
						¡registrate gratis!
					</BorderButton>
				</WhatsappContainer>
			</HeaderContainer>

			<SwipeableDrawer
				className={classes.drawer}
				anchor="left"
				open={openDrawer}
				onOpen={handleDrawerOpen}
				onClose={handleDrawerOpen}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
				>
					<Logo onClick={onGoHome} />
				</IconButton>
				<Divider />
				<List>
					<ListItem button>
						<StyledTypography
							variant="h6"
							style={{
								...getNavItemStyles(Tabs.Home),
							}}
							onClick={onGoHome}
						>
							Inicio
						</StyledTypography>
					</ListItem>
					<ListItem button>
						<StyledTypography
							variant="h6"
							style={{
								...getNavItemStyles(Tabs.AIDiagnostic),
							}}
							onClick={goToAiPage}
						>
							Diagnóstico con IA
						</StyledTypography>
					</ListItem>
					<ListItem button >
						<StyledTypography variant='h6'
							style={{
								...getNavItemStyles(Tabs.GoToApp)
							}} onClick={goToAppPulseara}>Iniciar sesión</StyledTypography>
					</ListItem>
					<ListItem button >
						<StyledTypography variant='h6'
							style={{
								...getNavItemStyles(Tabs.GoToApp)
							}} onClick={goToAppPulseara}>Iniciar sesión</StyledTypography>
					</ListItem>
				</List>
			</SwipeableDrawer>
		</>
	);
};

export default Header;

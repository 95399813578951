import React, {FC} from 'react';
import { IconButton } from '@material-ui/core';
import './CustomIconButton.style.css';

interface props {
    onClick: () => void;
    onMouseOver:  (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled: boolean;
    text: string;
}

const CustomIconButton: FC<props> = ({ onClick, onMouseOver, disabled, text }) => {
    return (
        <IconButton
            size="small"
            color="primary"
            aria-label="resend code"
            className='btn'
            onClick={onClick}
            onMouseDown={onMouseOver}
            disabled={disabled}
            edge="end"
        >
            {text}
        </IconButton>
    )
}

export default CustomIconButton;
import React, { FC, useContext, useState } from 'react';
import { RegisterFormSection } from '../components/formSection/style';
import { ContainerCenter, RegisterContainer, StyledInput, TrialButton } from '../share/style';
import { UserContext } from '../context/UserContext';
import { CreateDoctorResponseMessageType, UserContextType } from '../context/type';
import phoneMask from '../components/masks/phoneMask';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, SubTitle, Title } from '../components/register/style';

import { CircularProgress } from '@material-ui/core'
import TermsAndConditions from '../components/termsAndConditions/TermsAndconditions';
import HasAccountMessage from '../components/termsAndConditions/HasAccountMessage';
import useTolt from '../hooks/useTolt';

interface Props {
	isNormalNavBar: Boolean
}

const Register:FC<Props> = ({isNormalNavBar}) => {
    const history = useHistory();
    const location = useLocation();
	const { user, isInvalidEmail, handleChangeUser, handleChangeEmail, createDoctor, setAlertMessage } =
		useContext(UserContext) as UserContextType;
        const [loading, setLoading] = useState<boolean>(false)
    
	const [isSubmitted, setIsSubmitted] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    useTolt("ref")

    const isValidForm = () => {
        return user.email && user.name && user.phone && user.phone.length === 14;
    }
    
	const handleTry = async () => {
		try {
			if (isValidForm()) {
				setLoading(true)
				const isTrial = queryParams.get('isTrial') === 'false';                
				const useCreditCard = queryParams.get('useCreditCard') === 'false';
                const needVerification = queryParams.get('needVerification') === 'false';
				const response = await createDoctor(user.email, !isTrial, !useCreditCard, !needVerification);

				if (response === CreateDoctorResponseMessageType.EMAIL_ALREADY_EXISTS) {
					history.push({
						pathname: '/register-before/',
					});
					return;
				};

                if(response === CreateDoctorResponseMessageType.ERROR) {
					return;
				}

                if (response === CreateDoctorResponseMessageType.NO_VALIDATION_NEEDED) {
                    history.push({
                        pathname: '/temp-password-confirmation',
                    });
                    return
                }

                if (response === CreateDoctorResponseMessageType.PASSWORD_PENDING) {
					history.push({
						pathname: '/complete-registration',
					});
					return
				}

                
				history.push({
					pathname: '/confirmation/',
					state: { email: user.email },
				});
                
			} else {
				setIsSubmitted(true)
			}
		} catch (error) {
			setAlertMessage({
				message: 'Error al crear el doctor',
				severity: 'error',
			});
			setLoading(false)

		}

	}

    return (
        <RegisterContainer style={{
            justifyContent: 'space-between',
            overflowX: 'hidden',
        }}>
            <Header isNormalNavBar={isNormalNavBar} />
            <ContainerCenter>
                <Title>
                    ¡Regístrate gratis!
                </Title>
                <SubTitle>
                    Obtén acceso gratis por 14 días y simplifica tu práctica médica desde ahora y para siempre
                </SubTitle>
                <Form item>
                    <RegisterFormSection>
                        <StyledInput
                            id='name'
                            fullWidth
                            name='name'
                            value={user.name}
                            onChange={handleChangeUser}
                            margin='dense'
                            placeholder='Nombre completo*'
                            variant='outlined'
                            helperText={
                                isSubmitted &&
                                user.name === '' &&
                                'El nombre completo es requerido'
                            }
                            error={isSubmitted && user.name === ''}
                        />
                        <StyledInput
                            id='email'
                            fullWidth
                            margin='dense'
                            name='email'
                            value={user.email}
                            onChange={handleChangeEmail}
                            type='email'
                            placeholder='Correo*'
                            variant='outlined'
                            helperText={
                                isInvalidEmail
                                    ? 'El correo no es válido'
                                    : isSubmitted &&
                                    user.email === '' &&
                                    'El correo es requerido'
                            }
                            error={isInvalidEmail || (isSubmitted && user.email === '')}
                        />
                        <StyledInput
                            id='phone'
                            fullWidth
                            margin='dense'
                            name='phone'
                            value={user.phone}
                            onChange={handleChangeUser}
                            placeholder='Número de celular*'
                            variant='outlined'
                            InputProps={{ inputComponent: phoneMask as any }}
                            helperText={
                                isSubmitted && user.phone === '' && 'El telefono es requerido' ||
                                isSubmitted && user.phone.length < 14 && 'El telefono debe tener 10 dígitos'
                            }
                            error={isSubmitted && user.phone === '' ||
                                isSubmitted && user.phone.length < 14}
                        />
                        <TrialButton
                            fullWidth
                            variant='contained'
                            color='primary'
                            size='small'
                            style={{
                                maxWidth: '315px',
                            }}
                            onClick={handleTry}
							disabled={loading}
                        >
                            <span>probar gratis</span> 
                            { loading ? <CircularProgress size={20}  /> : null }
                        </TrialButton>
                    </RegisterFormSection>
                  
                <TermsAndConditions fullWidth />  
                </Form>
            <HasAccountMessage />
            </ContainerCenter>
            <Footer />
        </RegisterContainer>
    );
};

export default Register;
/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSecretary = /* GraphQL */ `
  query GetSecretary($id: ID!) {
    getSecretary(id: $id) {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSecretarys = /* GraphQL */ `
  query ListSecretarys(
    $filter: ModelSecretaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecretarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        firstTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSecretaries = /* GraphQL */ `
  query SyncSecretaries(
    $filter: ModelSecretaryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSecretaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        email
        firstTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSecretaryHospitalDoctor = /* GraphQL */ `
  query GetSecretaryHospitalDoctor($id: ID!) {
    getSecretaryHospitalDoctor(id: $id) {
      id
      hospitalDoctorID
      secretaryID
      hasHistoryPermission
      hasSurgeryPermission
      hasPrescriptionsPermission
      hasFilesPermission
      hasNotesPermission
      hasClaimsPermission
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSecretaryHospitalDoctors = /* GraphQL */ `
  query ListSecretaryHospitalDoctors(
    $filter: ModelSecretaryHospitalDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecretaryHospitalDoctors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hospitalDoctorID
        secretaryID
        hasHistoryPermission
        hasSurgeryPermission
        hasPrescriptionsPermission
        hasFilesPermission
        hasNotesPermission
        hasClaimsPermission
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSecretaryHospitalDoctors = /* GraphQL */ `
  query SyncSecretaryHospitalDoctors(
    $filter: ModelSecretaryHospitalDoctorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSecretaryHospitalDoctors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hospitalDoctorID
        secretaryID
        hasHistoryPermission
        hasSurgeryPermission
        hasPrescriptionsPermission
        hasFilesPermission
        hasNotesPermission
        hasClaimsPermission
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        identificationName
        identificationData
        actualInsurance
        name
        lastName
        cellphoneNumber
        email
        bornDate
        parentOrTutorCedula
        parentOrTutorID
        gender
        phoneNumber
        addressStreet
        city
        isAdult
        sector
        bloodType
        company
        occupation
        profileImage
        heartBeat
        weight
        height
        bloodPressure
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        identificationName
        identificationData
        actualInsurance
        name
        lastName
        cellphoneNumber
        email
        bornDate
        parentOrTutorCedula
        parentOrTutorID
        gender
        phoneNumber
        addressStreet
        city
        isAdult
        sector
        bloodType
        company
        occupation
        profileImage
        heartBeat
        weight
        height
        bloodPressure
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientImageSection = /* GraphQL */ `
  query GetClientImageSection($id: ID!) {
    getClientImageSection(id: $id) {
      id
      clientHospitalDoctorID
      date
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientImageSections = /* GraphQL */ `
  query ListClientImageSections(
    $filter: ModelClientImageSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientImageSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientHospitalDoctorID
        date
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientImageSections = /* GraphQL */ `
  query SyncClientImageSections(
    $filter: ModelClientImageSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientImageSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        date
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPosibleClient = /* GraphQL */ `
  query GetPosibleClient($id: ID!) {
    getPosibleClient(id: $id) {
      id
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPosibleClients = /* GraphQL */ `
  query ListPosibleClients(
    $filter: ModelPosibleClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosibleClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phoneNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPosibleClients = /* GraphQL */ `
  query SyncPosibleClients(
    $filter: ModelPosibleClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPosibleClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        phoneNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientImage = /* GraphQL */ `
  query GetClientImage($id: ID!) {
    getClientImage(id: $id) {
      id
      pathFile
      description
      ClientImageDateID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientImages = /* GraphQL */ `
  query ListClientImages(
    $filter: ModelClientImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pathFile
        description
        ClientImageDateID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientImages = /* GraphQL */ `
  query SyncClientImages(
    $filter: ModelClientImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pathFile
        description
        ClientImageDateID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConsultationDiagnostic = /* GraphQL */ `
  query GetConsultationDiagnostic($id: ID!) {
    getConsultationDiagnostic(id: $id) {
      id
      clientConsultationID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listConsultationDiagnostics = /* GraphQL */ `
  query ListConsultationDiagnostics(
    $filter: ModelConsultationDiagnosticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsultationDiagnostics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientConsultationID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConsultationDiagnostics = /* GraphQL */ `
  query SyncConsultationDiagnostics(
    $filter: ModelConsultationDiagnosticFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConsultationDiagnostics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientConsultationID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientConsultation = /* GraphQL */ `
  query GetClientConsultation($id: ID!) {
    getClientConsultation(id: $id) {
      id
      clientHospitalDoctorID
      visitReason
      visitDescription
      physicTestNote
      ta
      fc
      fr
      clientMedicinePrescriptionID
      clientAnalisisPrescriptionID
      treatment
      procedureName
      procedureDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClientConsultations = /* GraphQL */ `
  query ListClientConsultations(
    $filter: ModelClientConsultationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientHospitalDoctorID
        visitReason
        visitDescription
        physicTestNote
        ta
        fc
        fr
        clientMedicinePrescriptionID
        clientAnalisisPrescriptionID
        treatment
        procedureName
        procedureDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientConsultations = /* GraphQL */ `
  query SyncClientConsultations(
    $filter: ModelClientConsultationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientConsultations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        visitReason
        visitDescription
        physicTestNote
        ta
        fc
        fr
        clientMedicinePrescriptionID
        clientAnalisisPrescriptionID
        treatment
        procedureName
        procedureDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedicine = /* GraphQL */ `
  query GetMedicine($id: ID!) {
    getMedicine(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMedicines = /* GraphQL */ `
  query ListMedicines(
    $filter: ModelMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedicines = /* GraphQL */ `
  query SyncMedicines(
    $filter: ModelMedicineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMedicineMeasure = /* GraphQL */ `
  query GetMedicineMeasure($id: ID!) {
    getMedicineMeasure(id: $id) {
      id
      medicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMedicineMeasures = /* GraphQL */ `
  query ListMedicineMeasures(
    $filter: ModelMedicineMeasureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicineMeasures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medicineID
        measure
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMedicineMeasures = /* GraphQL */ `
  query SyncMedicineMeasures(
    $filter: ModelMedicineMeasureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMedicineMeasures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        medicineID
        measure
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientAllergy = /* GraphQL */ `
  query GetClientAllergy($id: ID!) {
    getClientAllergy(id: $id) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientAllergys = /* GraphQL */ `
  query ListClientAllergys(
    $filter: ModelClientAllergyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientAllergys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientAllergies = /* GraphQL */ `
  query SyncClientAllergies(
    $filter: ModelClientAllergyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientAllergies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientPrescription = /* GraphQL */ `
  query GetClientPrescription($id: ID!) {
    getClientPrescription(id: $id) {
      id
      clientHospitalDoctorID
      creationDate
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientPrescriptions = /* GraphQL */ `
  query ListClientPrescriptions(
    $filter: ModelClientPrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientPrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientHospitalDoctorID
        creationDate
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientPrescriptions = /* GraphQL */ `
  query SyncClientPrescriptions(
    $filter: ModelClientPrescriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientPrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        creationDate
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientPrescriptionMedicine = /* GraphQL */ `
  query GetClientPrescriptionMedicine($id: ID!) {
    getClientPrescriptionMedicine(id: $id) {
      id
      clientPrescriptionID
      medicineID
      name
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientPrescriptionMedicines = /* GraphQL */ `
  query ListClientPrescriptionMedicines(
    $filter: ModelClientPrescriptionMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientPrescriptionMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientPrescriptionID
        medicineID
        name
        selectedMeasure
        take
        indication
        duration
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientPrescriptionMedicines = /* GraphQL */ `
  query SyncClientPrescriptionMedicines(
    $filter: ModelClientPrescriptionMedicineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientPrescriptionMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientPrescriptionID
        medicineID
        name
        selectedMeasure
        take
        indication
        duration
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientPrescriptionAnalisis = /* GraphQL */ `
  query GetClientPrescriptionAnalisis($id: ID!) {
    getClientPrescriptionAnalisis(id: $id) {
      id
      clientPrescriptionID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientPrescriptionAnalisiss = /* GraphQL */ `
  query ListClientPrescriptionAnalisiss(
    $filter: ModelClientPrescriptionAnalisisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientPrescriptionAnalisiss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientPrescriptionID
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientPrescriptionAnalises = /* GraphQL */ `
  query SyncClientPrescriptionAnalises(
    $filter: ModelClientPrescriptionAnalisisFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientPrescriptionAnalises(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientPrescriptionID
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctorLabImage = /* GraphQL */ `
  query GetDoctorLabImage($id: ID!) {
    getDoctorLabImage(id: $id) {
      id
      doctorID
      labImageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDoctorLabImages = /* GraphQL */ `
  query ListDoctorLabImages(
    $filter: ModelDoctorLabImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorLabImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorID
        labImageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctorLabImages = /* GraphQL */ `
  query SyncDoctorLabImages(
    $filter: ModelDoctorLabImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorLabImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        labImageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLabImage = /* GraphQL */ `
  query GetLabImage($id: ID!) {
    getLabImage(id: $id) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listLabImages = /* GraphQL */ `
  query ListLabImages(
    $filter: ModelLabImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLabImages = /* GraphQL */ `
  query SyncLabImages(
    $filter: ModelLabImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLabImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPharmacyMedicine = /* GraphQL */ `
  query GetPharmacyMedicine($id: ID!) {
    getPharmacyMedicine(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPharmacyMedicines = /* GraphQL */ `
  query ListPharmacyMedicines(
    $filter: ModelPharmacyMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPharmacyMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPharmacyMedicines = /* GraphQL */ `
  query SyncPharmacyMedicines(
    $filter: ModelPharmacyMedicineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPharmacyMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPharmacyMedicineMeasure = /* GraphQL */ `
  query GetPharmacyMedicineMeasure($id: ID!) {
    getPharmacyMedicineMeasure(id: $id) {
      id
      pharmacyMedicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPharmacyMedicineMeasures = /* GraphQL */ `
  query ListPharmacyMedicineMeasures(
    $filter: ModelPharmacyMedicineMeasureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPharmacyMedicineMeasures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pharmacyMedicineID
        measure
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPharmacyMedicineMeasures = /* GraphQL */ `
  query SyncPharmacyMedicineMeasures(
    $filter: ModelPharmacyMedicineMeasureFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPharmacyMedicineMeasures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pharmacyMedicineID
        measure
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      doctorID
      templateName
      templateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorID
        templateName
        templateType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemplates = /* GraphQL */ `
  query SyncTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        templateName
        templateType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemplateMedicine = /* GraphQL */ `
  query GetTemplateMedicine($id: ID!) {
    getTemplateMedicine(id: $id) {
      id
      templateID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTemplateMedicines = /* GraphQL */ `
  query ListTemplateMedicines(
    $filter: ModelTemplateMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateID
        medicineID
        selectedMeasure
        take
        indication
        duration
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemplateMedicines = /* GraphQL */ `
  query SyncTemplateMedicines(
    $filter: ModelTemplateMedicineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemplateMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        templateID
        medicineID
        selectedMeasure
        take
        indication
        duration
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctorRecentDiagnosis = /* GraphQL */ `
  query GetDoctorRecentDiagnosis($id: ID!) {
    getDoctorRecentDiagnosis(id: $id) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDoctorRecentDiagnosiss = /* GraphQL */ `
  query ListDoctorRecentDiagnosiss(
    $filter: ModelDoctorRecentDiagnosisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorRecentDiagnosiss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctorRecentDiagnoses = /* GraphQL */ `
  query SyncDoctorRecentDiagnoses(
    $filter: ModelDoctorRecentDiagnosisFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorRecentDiagnoses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemplateLabImage = /* GraphQL */ `
  query GetTemplateLabImage($id: ID!) {
    getTemplateLabImage(id: $id) {
      id
      templateID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTemplateLabImages = /* GraphQL */ `
  query ListTemplateLabImages(
    $filter: ModelTemplateLabImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateLabImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateID
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemplateLabImages = /* GraphQL */ `
  query SyncTemplateLabImages(
    $filter: ModelTemplateLabImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemplateLabImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        templateID
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctorMedicine = /* GraphQL */ `
  query GetDoctorMedicine($id: ID!) {
    getDoctorMedicine(id: $id) {
      id
      doctorID
      medicineID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDoctorMedicines = /* GraphQL */ `
  query ListDoctorMedicines(
    $filter: ModelDoctorMedicineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorID
        medicineID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctorMedicines = /* GraphQL */ `
  query SyncDoctorMedicines(
    $filter: ModelDoctorMedicineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorMedicines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        medicineID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRecentDoctorMedicinePrescription = /* GraphQL */ `
  query GetRecentDoctorMedicinePrescription($id: ID!) {
    getRecentDoctorMedicinePrescription(id: $id) {
      id
      doctorID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRecentDoctorMedicinePrescriptions = /* GraphQL */ `
  query ListRecentDoctorMedicinePrescriptions(
    $filter: ModelRecentDoctorMedicinePrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecentDoctorMedicinePrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        medicineID
        selectedMeasure
        take
        indication
        duration
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRecentDoctorMedicinePrescriptions = /* GraphQL */ `
  query SyncRecentDoctorMedicinePrescriptions(
    $filter: ModelRecentDoctorMedicinePrescriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecentDoctorMedicinePrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        medicineID
        selectedMeasure
        take
        indication
        duration
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRecentDoctorLabImagePrescription = /* GraphQL */ `
  query GetRecentDoctorLabImagePrescription($id: ID!) {
    getRecentDoctorLabImagePrescription(id: $id) {
      id
      doctorID
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRecentDoctorLabImagePrescriptions = /* GraphQL */ `
  query ListRecentDoctorLabImagePrescriptions(
    $filter: ModelRecentDoctorLabImagePrescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecentDoctorLabImagePrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRecentDoctorLabImagePrescriptions = /* GraphQL */ `
  query SyncRecentDoctorLabImagePrescriptions(
    $filter: ModelRecentDoctorLabImagePrescriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecentDoctorLabImagePrescriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTemplateAnalisis = /* GraphQL */ `
  query GetTemplateAnalisis($id: ID!) {
    getTemplateAnalisis(id: $id) {
      id
      templateID
      analisisID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTemplateAnalisiss = /* GraphQL */ `
  query ListTemplateAnalisiss(
    $filter: ModelTemplateAnalisisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateAnalisiss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templateID
        analisisID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTemplateAnalises = /* GraphQL */ `
  query SyncTemplateAnalises(
    $filter: ModelTemplateAnalisisFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTemplateAnalises(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        templateID
        analisisID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAnalisis = /* GraphQL */ `
  query GetAnalisis($id: ID!) {
    getAnalisis(id: $id) {
      id
      analisisName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAnalisiss = /* GraphQL */ `
  query ListAnalisiss(
    $filter: ModelAnalisisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalisiss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        analisisName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAnalises = /* GraphQL */ `
  query SyncAnalises(
    $filter: ModelAnalisisFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnalises(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        analisisName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientActiveDisease = /* GraphQL */ `
  query GetClientActiveDisease($id: ID!) {
    getClientActiveDisease(id: $id) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientActiveDiseases = /* GraphQL */ `
  query ListClientActiveDiseases(
    $filter: ModelClientActiveDiseaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientActiveDiseases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientActiveDiseases = /* GraphQL */ `
  query SyncClientActiveDiseases(
    $filter: ModelClientActiveDiseaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientActiveDiseases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientHistory = /* GraphQL */ `
  query GetClientHistory($id: ID!) {
    getClientHistory(id: $id) {
      id
      clientHospitalDoctorID
      title
      treatment
      endModifyDate
      authorizationNumber
      description
      authorizationDetail
      personalPathologicalHistory
      familyPathologicalHistory
      physicalTest
      createdDate
      insuranceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientHistorys = /* GraphQL */ `
  query ListClientHistorys(
    $filter: ModelClientHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientHospitalDoctorID
        title
        treatment
        endModifyDate
        authorizationNumber
        description
        authorizationDetail
        personalPathologicalHistory
        familyPathologicalHistory
        physicalTest
        createdDate
        insuranceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientHistories = /* GraphQL */ `
  query SyncClientHistories(
    $filter: ModelClientHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        title
        treatment
        endModifyDate
        authorizationNumber
        description
        authorizationDetail
        personalPathologicalHistory
        familyPathologicalHistory
        physicalTest
        createdDate
        insuranceID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientNotes = /* GraphQL */ `
  query GetClientNotes($id: ID!) {
    getClientNotes(id: $id) {
      id
      clientHospitalDoctorID
      title
      noteBody
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientNotess = /* GraphQL */ `
  query ListClientNotess(
    $filter: ModelClientNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientNotess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientHospitalDoctorID
        title
        noteBody
        endModifyDate
        createdDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientNotes = /* GraphQL */ `
  query SyncClientNotes(
    $filter: ModelClientNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        title
        noteBody
        endModifyDate
        createdDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientProcedureSurgery = /* GraphQL */ `
  query GetClientProcedureSurgery($id: ID!) {
    getClientProcedureSurgery(id: $id) {
      id
      clientHospitalDoctorID
      title
      description
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientProcedureSurgerys = /* GraphQL */ `
  query ListClientProcedureSurgerys(
    $filter: ModelClientProcedureSurgeryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientProcedureSurgerys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientHospitalDoctorID
        title
        description
        endModifyDate
        createdDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientProcedureSurgeries = /* GraphQL */ `
  query SyncClientProcedureSurgeries(
    $filter: ModelClientProcedureSurgeryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientProcedureSurgeries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        title
        description
        endModifyDate
        createdDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctors = /* GraphQL */ `
  query SyncDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      refID
      title
      subTitle
      stripePlanKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        refID
        title
        subTitle
        stripePlanKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlans = /* GraphQL */ `
  query SyncPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        refID
        title
        subTitle
        stripePlanKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctorStripeCustomerSubscription = /* GraphQL */ `
  query GetDoctorStripeCustomerSubscription($id: ID!) {
    getDoctorStripeCustomerSubscription(id: $id) {
      id
      doctorID
      currentPlanID
      stripeCustomerID
      stripeSubscriptionID
      defaultCard
      nextPaymentDate
      cancelAtPeriodEnd
      trialsEndsAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDoctorStripeCustomerSubscriptions = /* GraphQL */ `
  query ListDoctorStripeCustomerSubscriptions(
    $filter: ModelDoctorStripeCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorStripeCustomerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorID
        currentPlanID
        stripeCustomerID
        stripeSubscriptionID
        defaultCard
        nextPaymentDate
        cancelAtPeriodEnd
        trialsEndsAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctorStripeCustomerSubscriptions = /* GraphQL */ `
  query SyncDoctorStripeCustomerSubscriptions(
    $filter: ModelDoctorStripeCustomerSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorStripeCustomerSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        currentPlanID
        stripeCustomerID
        stripeSubscriptionID
        defaultCard
        nextPaymentDate
        cancelAtPeriodEnd
        trialsEndsAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDoctorStripeCards = /* GraphQL */ `
  query GetDoctorStripeCards($id: ID!) {
    getDoctorStripeCards(id: $id) {
      id
      DoctorID
      cardLastDigits
      stripePaymentMethodID
      defaultCard
      cardBrand
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listDoctorStripeCardss = /* GraphQL */ `
  query ListDoctorStripeCardss(
    $filter: ModelDoctorStripeCardsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorStripeCardss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        DoctorID
        cardLastDigits
        stripePaymentMethodID
        defaultCard
        cardBrand
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDoctorStripeCards = /* GraphQL */ `
  query SyncDoctorStripeCards(
    $filter: ModelDoctorStripeCardsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDoctorStripeCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        DoctorID
        cardLastDigits
        stripePaymentMethodID
        defaultCard
        cardBrand
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHospital = /* GraphQL */ `
  query GetHospital($id: ID!) {
    getHospital(id: $id) {
      id
      name
      type
      province
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listHospitals = /* GraphQL */ `
  query ListHospitals(
    $filter: ModelHospitalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHospitals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        province
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHospitals = /* GraphQL */ `
  query SyncHospitals(
    $filter: ModelHospitalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHospitals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        province
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHospitalDoctor = /* GraphQL */ `
  query GetHospitalDoctor($id: ID!) {
    getHospitalDoctor(id: $id) {
      id
      doctorID
      hospitalID
      lastWaitingListID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listHospitalDoctors = /* GraphQL */ `
  query ListHospitalDoctors(
    $filter: ModelHospitalDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHospitalDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        doctorID
        hospitalID
        lastWaitingListID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHospitalDoctors = /* GraphQL */ `
  query SyncHospitalDoctors(
    $filter: ModelHospitalDoctorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHospitalDoctors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        doctorID
        hospitalID
        lastWaitingListID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHospitalDoctorCliente = /* GraphQL */ `
  query GetHospitalDoctorCliente($id: ID!) {
    getHospitalDoctorCliente(id: $id) {
      id
      clientID
      hospitalDoctorID
      lastHealthInsurranceID
      clientStatus
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listHospitalDoctorClientes = /* GraphQL */ `
  query ListHospitalDoctorClientes(
    $filter: ModelHospitalDoctorClienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHospitalDoctorClientes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        hospitalDoctorID
        lastHealthInsurranceID
        clientStatus
        note
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHospitalDoctorClientes = /* GraphQL */ `
  query SyncHospitalDoctorClientes(
    $filter: ModelHospitalDoctorClienteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHospitalDoctorClientes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientID
        hospitalDoctorID
        lastHealthInsurranceID
        clientStatus
        note
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWaitList = /* GraphQL */ `
  query GetWaitList($id: ID!) {
    getWaitList(id: $id) {
      id
      hospitalDoctorID
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listWaitLists = /* GraphQL */ `
  query ListWaitLists(
    $filter: ModelWaitListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hospitalDoctorID
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWaitLists = /* GraphQL */ `
  query SyncWaitLists(
    $filter: ModelWaitListFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWaitLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hospitalDoctorID
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWaitingListItem = /* GraphQL */ `
  query GetWaitingListItem($id: ID!) {
    getWaitingListItem(id: $id) {
      id
      waitingListID
      clientID
      status
      clientHealthInsurrance
      positionNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listWaitingListItems = /* GraphQL */ `
  query ListWaitingListItems(
    $filter: ModelWaitingListItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitingListItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        waitingListID
        clientID
        status
        clientHealthInsurrance
        positionNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWaitingListItems = /* GraphQL */ `
  query SyncWaitingListItems(
    $filter: ModelWaitingListItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWaitingListItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        waitingListID
        clientID
        status
        clientHealthInsurrance
        positionNumber
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientInsurance = /* GraphQL */ `
  query GetClientInsurance($id: ID!) {
    getClientInsurance(id: $id) {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientInsurances = /* GraphQL */ `
  query ListClientInsurances(
    $filter: ModelClientInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientInsurances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        insuranceID
        clientID
        contractNumber
        affiliateNumber
        affiliateType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientInsurances = /* GraphQL */ `
  query SyncClientInsurances(
    $filter: ModelClientInsuranceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientInsurances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        insuranceID
        clientID
        contractNumber
        affiliateNumber
        affiliateType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInsurance = /* GraphQL */ `
  query GetInsurance($id: ID!) {
    getInsurance(id: $id) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listInsurances = /* GraphQL */ `
  query ListInsurances(
    $filter: ModelInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInsurances = /* GraphQL */ `
  query SyncInsurances(
    $filter: ModelInsuranceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInsurances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClientClaim = /* GraphQL */ `
  query GetClientClaim($id: ID!) {
    getClientClaim(id: $id) {
      id
      clientHospitalDoctorID
      healthInsuranceID
      diagnostic
      authorization
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClientClaims = /* GraphQL */ `
  query ListClientClaims(
    $filter: ModelClientClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientHospitalDoctorID
        healthInsuranceID
        diagnostic
        authorization
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientClaims = /* GraphQL */ `
  query SyncClientClaims(
    $filter: ModelClientClaimFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientClaims(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientHospitalDoctorID
        healthInsuranceID
        diagnostic
        authorization
        date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClaimProcess = /* GraphQL */ `
  query GetClaimProcess($id: ID!) {
    getClaimProcess(id: $id) {
      id
      claimID
      name
      code
      claimed
      difference
      authorized
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClaimProcesss = /* GraphQL */ `
  query ListClaimProcesss(
    $filter: ModelClaimProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClaimProcesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        claimID
        name
        code
        claimed
        difference
        authorized
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClaimProcesses = /* GraphQL */ `
  query SyncClaimProcesses(
    $filter: ModelClaimProcessFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClaimProcesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        claimID
        name
        code
        claimed
        difference
        authorized
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

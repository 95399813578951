import React, {
	FC,
} from 'react'
import {
	FooterContainer,
	LogoContainer,
	WhatsappContainer,
	StyledWhatsAppIcon,
	WhatsappLink,
} from './style'
import Logo from '../svg/Logo'
import { useHistory } from 'react-router-dom'

const Footer:FC<{
	height?: string,
	containerStyles?: React.CSSProperties,
}> = ({
	height,
	containerStyles,
}) => {
	const history = useHistory()

	return (
		<FooterContainer style={{
			height: height,
			...containerStyles,
		}}>
			<LogoContainer item xs={6}>
				<Logo onClick={() => history.push('/')} />
			</LogoContainer>
			<WhatsappContainer item xs={6}>
				<StyledWhatsAppIcon />
				<WhatsappLink
					target='_blank'
					rel='noreferrer'
					href="https://wa.me/+18098705294?text=Saludos%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20detalles%20acerca%20de%20su%20aplicaci%C3%B3n%20y%20los%20servicios%20que%20ofrecen"
				>
					+1 (829) 653-2245
				</WhatsappLink>
			</WhatsappContainer>
		</FooterContainer>
	)
}

export default Footer

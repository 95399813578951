import { Grid } from '@material-ui/core'
import {
	InformationrContainer,
	InformationContainer,
	VideoTitleTypography,
	ColumnContainer,
	ColInformationContainer,
	LineYellowColor,
	InformationTypography,
	ListContainer,
	LineGreenColor,
	LineGrayColor,
	LineBlueColor,
	VideoTitleContainer,
} from './style'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { URL_VIDEO, infColumn1, infColumn2 } from '../../share/constant'
import { BorderButton } from '../../share/style'
import { useState } from 'react'
import VideoPlayer from '../videoPlayer/VideoPlayer'
import ReactPlayer from 'react-player'

const InformationSection = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false)

	const openAndPlayVideo = () => {
		setOpen(true)
		setIsPlayingVideo(true)
	}

	const closeAndStopVideo = () => {
		setOpen(false)
		setIsPlayingVideo(false)
	}

	const handleOpenVideo = () => {
		openAndPlayVideo()
	}

	const getLineColor = (color: string) => {
		let lineColor = <LineYellowColor />
		if (color === 'green') {
			lineColor = <LineGreenColor />
		}
		if (color === 'gray') {
			lineColor = <LineGrayColor />
		}
		if (color === 'blue') {
			lineColor = <LineBlueColor />
		}
		return lineColor
	}

	return (
		<>
			<VideoPlayer open={open} handleClose={closeAndStopVideo}>
				<ReactPlayer
					className='react-player'
					url={URL_VIDEO}
					loop={true}
					playing={isPlayingVideo}
					controls={true}
					config={{
						youtube: {
							playerVars: { showinfo: 1 },
						},
					}}
					style={{ maxWidth: '870.3px' }}
					height={'501.2px'}
				/>
			</VideoPlayer>
			<InformationrContainer>
				<VideoTitleContainer item xs={12} md={3}>
					<VideoTitleTypography>
						Un paso a la vez. Una solución para cada problema.
					</VideoTitleTypography>
					<BorderButton
						variant='outlined'
						color='primary'
						startIcon={<PlayCircleFilledIcon />}
						onClick={handleOpenVideo}
					>
						Ver Demo
					</BorderButton>
				</VideoTitleContainer>
				<ColumnContainer item xs={9} md={9}>
					<InformationContainer item xs={6} md={6}>
						{infColumn1.map((info) => (
							<ColInformationContainer item xs={12} key={info.id}>
								<Grid item xs={12}>
									{getLineColor(info.color)}
									<InformationTypography>{info.title}</InformationTypography>
									<ListContainer>
										{info.body.map((list) => (
											<li key={list.id}>{list.text}</li>
										))}
									</ListContainer>
								</Grid>
							</ColInformationContainer>
						))}
					</InformationContainer>
					<InformationContainer item xs={6} md={6}>
						{infColumn2.map((info) => (
							<ColInformationContainer item xs={12} key={info.id}>
								<Grid item xs={12}>
									{getLineColor(info.color)}
									<InformationTypography>{info.title}</InformationTypography>
									<ListContainer>
										{info.body.map((list) => (
											<li key={list.id}>{list.text}</li>
										))}
									</ListContainer>
								</Grid>
							</ColInformationContainer>
						))}
					</InformationContainer>
				</ColumnContainer>
			</InformationrContainer>
		</>
	)
}

export default InformationSection

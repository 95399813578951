import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useTolt = (variant: string) => {
    const [refId, setRefId] = useState<string | null>(null);
    const history = useHistory();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const ref = params.get(variant);

    if (ref) {
        localStorage.setItem(variant, ref);
        params.delete(variant);
        history.replace({
            search: params.toString(),
        });

        // @ts-ignore
        const ref_value = window.tolt_referral
        if (ref_value) {
            setRefId(ref_value);
        }
    }

    const getRefStored = () => {
        const ref = localStorage.getItem(variant);
        if (ref) {
            return ref;
        }
        return null;
    }

    const removeRefStored = () => {
        localStorage.removeItem(variant);
    }

    return {
        refId,
        getRefStored,
        removeRefStored
    };
};

export default useTolt;
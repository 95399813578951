import React, { useState, useEffect, useContext } from 'react';
import { AIContextProps } from './type';
import { AIDiagnosisSuggestion, AIMedicalPlanSuggestion, AIPatienDiagnostic, AISection, AISuggestion, AIType, PatientData } from '../share/ai/types';
import { promtMedicalPlan } from '../share/ai/promts/promtMedicalPlan';
import { promtDiagnosis } from '../share/ai/promts/promtDiagnosis';
import AI from '../share/ai/AiSuggestions';

export const AiContext = React.createContext<Partial<AIContextProps>>({});

const AiProvider: React.FC = ({ children }) => {
  const [aiPromptInput, setAIPromptInput] = useState<AIPatienDiagnostic | null>(null);
  const [aiSuggestions, setAiSuggestions] = useState<AISuggestion | AIMedicalPlanSuggestion | AIDiagnosisSuggestion | null>(null);
  const [aiSection, setAiSection] = useState<AISection | null>('consultation');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (aiPromptInput) {
      try {
        setIsLoading(true);
        const generatePromptOutput = async () => {
          const callback = aiSection === 'diagnosis' ? promtDiagnosis : promtMedicalPlan;

          const suggestions = await AI(aiPromptInput, callback);
          let input: AISuggestion | AIMedicalPlanSuggestion | AIDiagnosisSuggestion;
          if(aiSection === 'medical_plan') {
            input = {
              diagnosticos: suggestions.diagnosticos.map((diagnostico: any) => ({
                nombre: diagnostico.nombre,
              })),
              estudios: suggestions.estudios.map((estudio: any) => ({
                nombre: estudio.nombre,
              })),
              medicamentos: suggestions.medicamentos.map((medicamento: any) => ({
                nombre: medicamento.nombre,
              })),
              planMedico: suggestions?.planMedico ? suggestions.planMedico[0].descripcion : '',
              tratamientos: suggestions.tratamientos.map((tratamiento: any) => ({
                nombre: tratamiento.nombre,
              })),
            } as AIMedicalPlanSuggestion;
          } else if (aiSection === 'diagnosis') {
            input = {
              diagnosticos: suggestions.diagnosticos
            } as AIDiagnosisSuggestion;
          } else {
            input = {
              ...suggestions,
              diagnosticosDiferenciales: suggestions.diagnosticos
            } as AISuggestion;
          }

          setAiSuggestions(input);
        };

        generatePromptOutput()
        .then(() =>{
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('Error IA', error);
          setIsLoading(false);
        });
      } catch (error: any) {
        console.log('Error IA', error);
        setIsLoading(false);
      }
    }
  }, [aiPromptInput]);

  useEffect(() => {
    if (!aiSection) {
      setAiSuggestions(null);
      setAIPromptInput(null);
    }
  }, [aiSection]);

  const clearSuggestions = () => {
    setAiSuggestions(null);
  };

  return (
    <AiContext.Provider
      value={{
        aiSuggestions,
        setIAPrompt: setAIPromptInput,
        clearIASuggestions: clearSuggestions,
        setAiSection,
        aiSection,
        loading: isLoading,
        setLoading: setIsLoading,
      }}
    >
      {children}
    </AiContext.Provider>
  );
};

export default AiProvider;

import {
	Link,
	Button,
	Card,
	CardContent,
	createTheme,
	TextField,
	Typography,
	withStyles,
	CardHeader,
	Grid,
	CircularProgress
} from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import styled from 'styled-components'

const theme = createTheme({
	palette: {
		primary: {
			light: blue[300],
			main: blue[500],
			dark: blue[700],
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 744,
			md: 1025,
			lg: 1200,
			xl: 1920,
		},
	},
})

export const RegisterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	background: linear-gradient(to bottom, rgba(25, 118, 210, 0.08) 50%, #FFFFFF 50%);
	height: 100vh;
`;

export const StyledCardContent = withStyles({
	root: {
		margin: theme.spacing(1, 0, 2, 0),
		padding: theme.spacing(0.87, 2.75),
		fontFamily: 'Roboto',
		fontWeight: 500,
		backgroundColor: '#1976d2',
	},
})(CardContent) as typeof CardContent

export const TrialButton = withStyles({
	root: {
		maxWidth: '163px',
		'@media (min-width: 0px) and (max-width: 660px)': {
			width: '100%',
			maxWidth: '100%',
		},
		margin: theme.spacing(0.5, 0, 0, 0),
		padding: theme.spacing(0.87, 2.75),
		fontFamily: 'Roboto',
		fontWeight: 500,
		backgroundColor: '#1976d2',
		width: '100%',
		'& .MuiButton-label': {
			display: 'flex',
			flexDirection: 'row',
			columnGap: '10px',
		}

	},
	containedPrimary: {
		'&:hover': {
			backgroundColor: 'rgb(17, 82, 147)',
		},
	},
})(Button) as typeof Button

export const VideoButton = withStyles({
	root: {
		margin: theme.spacing(7, 5),
		fontFamily: 'Roboto',
		fontWeight: 500,
		letterSpacing: '0.46px',
		color: '#fff',
	},
})(Button) as typeof Button

export const StyledLink = withStyles({
	root: {
		color: '#1976d2',
		fontWeight: 'bold'
	},
})(Link) as typeof Link

export const ViewButton = withStyles({
	root: {
		maxWidth: '315px',
		color: '#1976d2',
	},
	outlinedPrimary: {
		'&:hover': {
			border: '1px solid #1976d2',
		},
	},
})(Button) as typeof Button

export const BorderButton = styled(ViewButton)`
	border: 1px solid;
	margin: 8px 0px 16px 0px;
	padding: 6.96px 22px;
`

export const ContainerCenter = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 30px;
	height: auto;

	margin-top: -100px;

	@media (min-width: 600px) and (max-width: 920px) {
		margin-top: 180px;
	}

	@media (max-width: 599.95px) {
		margin-top: 40px;
	}
`

export const RegisterBeforeContainer = styled(ContainerCenter)`
	height: calc(100vh - 67.75px);
	text-align: center;
	margin-top: 0;

	@media (max-width: 599.95px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100vh;
	}
`


export const TitleTypography = withStyles({
	root: {
		fontSize: '32px',
		lineHeight: 1.4,
		fontFamily: 'NotoSans',
		fontWeight: 400,
		marginBottom: theme.spacing(5.7),
	},
})(Typography) as typeof Typography

export const StyledCardHeader = withStyles({
	title: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '18px',
		},
		fontWeight: 400,
	},
})(CardHeader) as typeof CardHeader

export const RegisterBeforeTitle = styled(TitleTypography)`
	margin-top: 15px;
	@media (max-width: 599.95px) {
		font-size: 32px;
		margin-bottom: 10px;
	}
	margin-bottom: 0;
`

export const DescriptionTypography = withStyles({
	root: {
		fontSize: '16px',
		lineHeight: 1.5,
		maxWidth: '549px',
		fontFamily: 'Roboto',
		fontWeight: 400,
		color: '#424242',
		marginBottom: '20px',
	},
})(Typography) as typeof Typography

export const StyledInput = withStyles({
	root: {
		maxWidth: '315px',
		marginBottom: theme.spacing(2),
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#607d8b',
		},
		'& input::placeholder': {
			color: 'rgba(0, 0, 0, 0.6)',
			opacity: 1,
		},
	},
})(TextField) as typeof TextField

export const StyledCard = withStyles({
	root: {
		maxWidth: '490px',
		padding: theme.spacing(2, 3),
	},
})(Card) as typeof Card

export const SendButton = withStyles({
	root: {
		marginBottom: `${theme.spacing(1)}px`,
	},
})(Button) as typeof Button

export const AutoVideoButton = withStyles({
	root: {
		marginTop: `${theme.spacing(2)}px`,
		marginBottom: `${theme.spacing(5)}px`,
	},
})(Button) as typeof Button

export const VideoCardContent = withStyles({
	root: {
		color: '#000',
		padding: '1rem',
		textAlign: 'center',
		maxWidth: '852px',
		backgroundColor: '#ffff',
		borderRadius: '4px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'&:last-child': {
			paddingBottom: '50px',
		},
	},
})(Grid) as typeof Grid


export const StyledCircularProgress = withStyles({})(CircularProgress) as typeof CircularProgress

export const activeStyle = {
	fontWeight: '800'
}


export const normal = {
	backgroundColor: 'transparent',
}

export const shadowBox = {
	boxShadow:
		'0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.2)',
	backgroundColor: '#fff',
	display: 'flex',
	justifyContent: 'space-between',
}

export const  RegisterBeforeDescriptionText = styled(DescriptionTypography)`
  @media (max-width: 600px) {
    width: 300px;	
	margin: 0 auto;
`
import React, { FC } from "react"
import { ConditionLink, ConditionTypography } from "../formSection/style"


const HasAccountMessage:FC<{
    fullWidth?: boolean,
}> = ({
    fullWidth = false
}) => {
    return (<ConditionTypography fullWidth={fullWidth}>
        Ya tengo una cuenta, <ConditionLink href={process.env.REACT_APP_APP_PULSEARA_URL} target='_blank'>Iniciar sección</ConditionLink>
    </ConditionTypography>)
}

export default HasAccountMessage;
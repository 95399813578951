import { Configuration, OpenAIApi } from 'openai';
import { AIPatienDiagnostic, PatientData } from './types';

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

export default async function (data: AIPatienDiagnostic , cb:(data: AIPatienDiagnostic ) => string ) {
  if (!configuration.apiKey && !data) {
    throw new Error('No se pudo generar sugerencias de AI');
  }

  const maxRetries = 3;
  let retryCount = 0;

  while (retryCount < maxRetries) {
    try {
      const completion = await openai.createCompletion({
        model: 'gpt-3.5-turbo-instruct',
        prompt: cb(data),
        temperature: 0.7,
        max_tokens: 2000,
      });
      
      if (!completion?.data?.choices[0].text) {
        throw new Error('No se pudo generar sugerencias de AI');
      }

      return JSON.parse(completion?.data?.choices[0].text.replace('Respuesta:', ''));
    } catch (error: any) {

      retryCount++;
      if (retryCount === maxRetries) {
        throw new Error('No se pudo generar sugerencias de AI');
      }

      // Espera 1 segundo antes de volver a intentar
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
}

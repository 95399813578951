/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSecretary = /* GraphQL */ `
  mutation CreateSecretary(
    $input: CreateSecretaryInput!
    $condition: ModelSecretaryConditionInput
  ) {
    createSecretary(input: $input, condition: $condition) {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSecretary = /* GraphQL */ `
  mutation UpdateSecretary(
    $input: UpdateSecretaryInput!
    $condition: ModelSecretaryConditionInput
  ) {
    updateSecretary(input: $input, condition: $condition) {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecretary = /* GraphQL */ `
  mutation DeleteSecretary(
    $input: DeleteSecretaryInput!
    $condition: ModelSecretaryConditionInput
  ) {
    deleteSecretary(input: $input, condition: $condition) {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSecretaryHospitalDoctor = /* GraphQL */ `
  mutation CreateSecretaryHospitalDoctor(
    $input: CreateSecretaryHospitalDoctorInput!
    $condition: ModelSecretaryHospitalDoctorConditionInput
  ) {
    createSecretaryHospitalDoctor(input: $input, condition: $condition) {
      id
      hospitalDoctorID
      secretaryID
      hasHistoryPermission
      hasSurgeryPermission
      hasPrescriptionsPermission
      hasFilesPermission
      hasNotesPermission
      hasClaimsPermission
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSecretaryHospitalDoctor = /* GraphQL */ `
  mutation UpdateSecretaryHospitalDoctor(
    $input: UpdateSecretaryHospitalDoctorInput!
    $condition: ModelSecretaryHospitalDoctorConditionInput
  ) {
    updateSecretaryHospitalDoctor(input: $input, condition: $condition) {
      id
      hospitalDoctorID
      secretaryID
      hasHistoryPermission
      hasSurgeryPermission
      hasPrescriptionsPermission
      hasFilesPermission
      hasNotesPermission
      hasClaimsPermission
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecretaryHospitalDoctor = /* GraphQL */ `
  mutation DeleteSecretaryHospitalDoctor(
    $input: DeleteSecretaryHospitalDoctorInput!
    $condition: ModelSecretaryHospitalDoctorConditionInput
  ) {
    deleteSecretaryHospitalDoctor(input: $input, condition: $condition) {
      id
      hospitalDoctorID
      secretaryID
      hasHistoryPermission
      hasSurgeryPermission
      hasPrescriptionsPermission
      hasFilesPermission
      hasNotesPermission
      hasClaimsPermission
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createClientImageSection = /* GraphQL */ `
  mutation CreateClientImageSection(
    $input: CreateClientImageSectionInput!
    $condition: ModelClientImageSectionConditionInput
  ) {
    createClientImageSection(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      date
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientImageSection = /* GraphQL */ `
  mutation UpdateClientImageSection(
    $input: UpdateClientImageSectionInput!
    $condition: ModelClientImageSectionConditionInput
  ) {
    updateClientImageSection(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      date
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientImageSection = /* GraphQL */ `
  mutation DeleteClientImageSection(
    $input: DeleteClientImageSectionInput!
    $condition: ModelClientImageSectionConditionInput
  ) {
    deleteClientImageSection(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      date
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPosibleClient = /* GraphQL */ `
  mutation CreatePosibleClient(
    $input: CreatePosibleClientInput!
    $condition: ModelPosibleClientConditionInput
  ) {
    createPosibleClient(input: $input, condition: $condition) {
      id
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePosibleClient = /* GraphQL */ `
  mutation UpdatePosibleClient(
    $input: UpdatePosibleClientInput!
    $condition: ModelPosibleClientConditionInput
  ) {
    updatePosibleClient(input: $input, condition: $condition) {
      id
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePosibleClient = /* GraphQL */ `
  mutation DeletePosibleClient(
    $input: DeletePosibleClientInput!
    $condition: ModelPosibleClientConditionInput
  ) {
    deletePosibleClient(input: $input, condition: $condition) {
      id
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientImage = /* GraphQL */ `
  mutation CreateClientImage(
    $input: CreateClientImageInput!
    $condition: ModelClientImageConditionInput
  ) {
    createClientImage(input: $input, condition: $condition) {
      id
      pathFile
      description
      ClientImageDateID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientImage = /* GraphQL */ `
  mutation UpdateClientImage(
    $input: UpdateClientImageInput!
    $condition: ModelClientImageConditionInput
  ) {
    updateClientImage(input: $input, condition: $condition) {
      id
      pathFile
      description
      ClientImageDateID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientImage = /* GraphQL */ `
  mutation DeleteClientImage(
    $input: DeleteClientImageInput!
    $condition: ModelClientImageConditionInput
  ) {
    deleteClientImage(input: $input, condition: $condition) {
      id
      pathFile
      description
      ClientImageDateID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createConsultationDiagnostic = /* GraphQL */ `
  mutation CreateConsultationDiagnostic(
    $input: CreateConsultationDiagnosticInput!
    $condition: ModelConsultationDiagnosticConditionInput
  ) {
    createConsultationDiagnostic(input: $input, condition: $condition) {
      id
      clientConsultationID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateConsultationDiagnostic = /* GraphQL */ `
  mutation UpdateConsultationDiagnostic(
    $input: UpdateConsultationDiagnosticInput!
    $condition: ModelConsultationDiagnosticConditionInput
  ) {
    updateConsultationDiagnostic(input: $input, condition: $condition) {
      id
      clientConsultationID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsultationDiagnostic = /* GraphQL */ `
  mutation DeleteConsultationDiagnostic(
    $input: DeleteConsultationDiagnosticInput!
    $condition: ModelConsultationDiagnosticConditionInput
  ) {
    deleteConsultationDiagnostic(input: $input, condition: $condition) {
      id
      clientConsultationID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientConsultation = /* GraphQL */ `
  mutation CreateClientConsultation(
    $input: CreateClientConsultationInput!
    $condition: ModelClientConsultationConditionInput
  ) {
    createClientConsultation(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      visitReason
      visitDescription
      physicTestNote
      ta
      fc
      fr
      clientMedicinePrescriptionID
      clientAnalisisPrescriptionID
      treatment
      procedureName
      procedureDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateClientConsultation = /* GraphQL */ `
  mutation UpdateClientConsultation(
    $input: UpdateClientConsultationInput!
    $condition: ModelClientConsultationConditionInput
  ) {
    updateClientConsultation(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      visitReason
      visitDescription
      physicTestNote
      ta
      fc
      fr
      clientMedicinePrescriptionID
      clientAnalisisPrescriptionID
      treatment
      procedureName
      procedureDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteClientConsultation = /* GraphQL */ `
  mutation DeleteClientConsultation(
    $input: DeleteClientConsultationInput!
    $condition: ModelClientConsultationConditionInput
  ) {
    deleteClientConsultation(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      visitReason
      visitDescription
      physicTestNote
      ta
      fc
      fr
      clientMedicinePrescriptionID
      clientAnalisisPrescriptionID
      treatment
      procedureName
      procedureDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createMedicine = /* GraphQL */ `
  mutation CreateMedicine(
    $input: CreateMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    createMedicine(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicine = /* GraphQL */ `
  mutation UpdateMedicine(
    $input: UpdateMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    updateMedicine(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicine = /* GraphQL */ `
  mutation DeleteMedicine(
    $input: DeleteMedicineInput!
    $condition: ModelMedicineConditionInput
  ) {
    deleteMedicine(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMedicineMeasure = /* GraphQL */ `
  mutation CreateMedicineMeasure(
    $input: CreateMedicineMeasureInput!
    $condition: ModelMedicineMeasureConditionInput
  ) {
    createMedicineMeasure(input: $input, condition: $condition) {
      id
      medicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicineMeasure = /* GraphQL */ `
  mutation UpdateMedicineMeasure(
    $input: UpdateMedicineMeasureInput!
    $condition: ModelMedicineMeasureConditionInput
  ) {
    updateMedicineMeasure(input: $input, condition: $condition) {
      id
      medicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicineMeasure = /* GraphQL */ `
  mutation DeleteMedicineMeasure(
    $input: DeleteMedicineMeasureInput!
    $condition: ModelMedicineMeasureConditionInput
  ) {
    deleteMedicineMeasure(input: $input, condition: $condition) {
      id
      medicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientAllergy = /* GraphQL */ `
  mutation CreateClientAllergy(
    $input: CreateClientAllergyInput!
    $condition: ModelClientAllergyConditionInput
  ) {
    createClientAllergy(input: $input, condition: $condition) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientAllergy = /* GraphQL */ `
  mutation UpdateClientAllergy(
    $input: UpdateClientAllergyInput!
    $condition: ModelClientAllergyConditionInput
  ) {
    updateClientAllergy(input: $input, condition: $condition) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientAllergy = /* GraphQL */ `
  mutation DeleteClientAllergy(
    $input: DeleteClientAllergyInput!
    $condition: ModelClientAllergyConditionInput
  ) {
    deleteClientAllergy(input: $input, condition: $condition) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientPrescription = /* GraphQL */ `
  mutation CreateClientPrescription(
    $input: CreateClientPrescriptionInput!
    $condition: ModelClientPrescriptionConditionInput
  ) {
    createClientPrescription(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      creationDate
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientPrescription = /* GraphQL */ `
  mutation UpdateClientPrescription(
    $input: UpdateClientPrescriptionInput!
    $condition: ModelClientPrescriptionConditionInput
  ) {
    updateClientPrescription(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      creationDate
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientPrescription = /* GraphQL */ `
  mutation DeleteClientPrescription(
    $input: DeleteClientPrescriptionInput!
    $condition: ModelClientPrescriptionConditionInput
  ) {
    deleteClientPrescription(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      creationDate
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientPrescriptionMedicine = /* GraphQL */ `
  mutation CreateClientPrescriptionMedicine(
    $input: CreateClientPrescriptionMedicineInput!
    $condition: ModelClientPrescriptionMedicineConditionInput
  ) {
    createClientPrescriptionMedicine(input: $input, condition: $condition) {
      id
      clientPrescriptionID
      medicineID
      name
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientPrescriptionMedicine = /* GraphQL */ `
  mutation UpdateClientPrescriptionMedicine(
    $input: UpdateClientPrescriptionMedicineInput!
    $condition: ModelClientPrescriptionMedicineConditionInput
  ) {
    updateClientPrescriptionMedicine(input: $input, condition: $condition) {
      id
      clientPrescriptionID
      medicineID
      name
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientPrescriptionMedicine = /* GraphQL */ `
  mutation DeleteClientPrescriptionMedicine(
    $input: DeleteClientPrescriptionMedicineInput!
    $condition: ModelClientPrescriptionMedicineConditionInput
  ) {
    deleteClientPrescriptionMedicine(input: $input, condition: $condition) {
      id
      clientPrescriptionID
      medicineID
      name
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientPrescriptionAnalisis = /* GraphQL */ `
  mutation CreateClientPrescriptionAnalisis(
    $input: CreateClientPrescriptionAnalisisInput!
    $condition: ModelClientPrescriptionAnalisisConditionInput
  ) {
    createClientPrescriptionAnalisis(input: $input, condition: $condition) {
      id
      clientPrescriptionID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientPrescriptionAnalisis = /* GraphQL */ `
  mutation UpdateClientPrescriptionAnalisis(
    $input: UpdateClientPrescriptionAnalisisInput!
    $condition: ModelClientPrescriptionAnalisisConditionInput
  ) {
    updateClientPrescriptionAnalisis(input: $input, condition: $condition) {
      id
      clientPrescriptionID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientPrescriptionAnalisis = /* GraphQL */ `
  mutation DeleteClientPrescriptionAnalisis(
    $input: DeleteClientPrescriptionAnalisisInput!
    $condition: ModelClientPrescriptionAnalisisConditionInput
  ) {
    deleteClientPrescriptionAnalisis(input: $input, condition: $condition) {
      id
      clientPrescriptionID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDoctorLabImage = /* GraphQL */ `
  mutation CreateDoctorLabImage(
    $input: CreateDoctorLabImageInput!
    $condition: ModelDoctorLabImageConditionInput
  ) {
    createDoctorLabImage(input: $input, condition: $condition) {
      id
      doctorID
      labImageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctorLabImage = /* GraphQL */ `
  mutation UpdateDoctorLabImage(
    $input: UpdateDoctorLabImageInput!
    $condition: ModelDoctorLabImageConditionInput
  ) {
    updateDoctorLabImage(input: $input, condition: $condition) {
      id
      doctorID
      labImageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctorLabImage = /* GraphQL */ `
  mutation DeleteDoctorLabImage(
    $input: DeleteDoctorLabImageInput!
    $condition: ModelDoctorLabImageConditionInput
  ) {
    deleteDoctorLabImage(input: $input, condition: $condition) {
      id
      doctorID
      labImageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createLabImage = /* GraphQL */ `
  mutation CreateLabImage(
    $input: CreateLabImageInput!
    $condition: ModelLabImageConditionInput
  ) {
    createLabImage(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateLabImage = /* GraphQL */ `
  mutation UpdateLabImage(
    $input: UpdateLabImageInput!
    $condition: ModelLabImageConditionInput
  ) {
    updateLabImage(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLabImage = /* GraphQL */ `
  mutation DeleteLabImage(
    $input: DeleteLabImageInput!
    $condition: ModelLabImageConditionInput
  ) {
    deleteLabImage(input: $input, condition: $condition) {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPharmacyMedicine = /* GraphQL */ `
  mutation CreatePharmacyMedicine(
    $input: CreatePharmacyMedicineInput!
    $condition: ModelPharmacyMedicineConditionInput
  ) {
    createPharmacyMedicine(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePharmacyMedicine = /* GraphQL */ `
  mutation UpdatePharmacyMedicine(
    $input: UpdatePharmacyMedicineInput!
    $condition: ModelPharmacyMedicineConditionInput
  ) {
    updatePharmacyMedicine(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePharmacyMedicine = /* GraphQL */ `
  mutation DeletePharmacyMedicine(
    $input: DeletePharmacyMedicineInput!
    $condition: ModelPharmacyMedicineConditionInput
  ) {
    deletePharmacyMedicine(input: $input, condition: $condition) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPharmacyMedicineMeasure = /* GraphQL */ `
  mutation CreatePharmacyMedicineMeasure(
    $input: CreatePharmacyMedicineMeasureInput!
    $condition: ModelPharmacyMedicineMeasureConditionInput
  ) {
    createPharmacyMedicineMeasure(input: $input, condition: $condition) {
      id
      pharmacyMedicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePharmacyMedicineMeasure = /* GraphQL */ `
  mutation UpdatePharmacyMedicineMeasure(
    $input: UpdatePharmacyMedicineMeasureInput!
    $condition: ModelPharmacyMedicineMeasureConditionInput
  ) {
    updatePharmacyMedicineMeasure(input: $input, condition: $condition) {
      id
      pharmacyMedicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePharmacyMedicineMeasure = /* GraphQL */ `
  mutation DeletePharmacyMedicineMeasure(
    $input: DeletePharmacyMedicineMeasureInput!
    $condition: ModelPharmacyMedicineMeasureConditionInput
  ) {
    deletePharmacyMedicineMeasure(input: $input, condition: $condition) {
      id
      pharmacyMedicineID
      measure
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      doctorID
      templateName
      templateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      doctorID
      templateName
      templateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      doctorID
      templateName
      templateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTemplateMedicine = /* GraphQL */ `
  mutation CreateTemplateMedicine(
    $input: CreateTemplateMedicineInput!
    $condition: ModelTemplateMedicineConditionInput
  ) {
    createTemplateMedicine(input: $input, condition: $condition) {
      id
      templateID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplateMedicine = /* GraphQL */ `
  mutation UpdateTemplateMedicine(
    $input: UpdateTemplateMedicineInput!
    $condition: ModelTemplateMedicineConditionInput
  ) {
    updateTemplateMedicine(input: $input, condition: $condition) {
      id
      templateID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplateMedicine = /* GraphQL */ `
  mutation DeleteTemplateMedicine(
    $input: DeleteTemplateMedicineInput!
    $condition: ModelTemplateMedicineConditionInput
  ) {
    deleteTemplateMedicine(input: $input, condition: $condition) {
      id
      templateID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDoctorRecentDiagnosis = /* GraphQL */ `
  mutation CreateDoctorRecentDiagnosis(
    $input: CreateDoctorRecentDiagnosisInput!
    $condition: ModelDoctorRecentDiagnosisConditionInput
  ) {
    createDoctorRecentDiagnosis(input: $input, condition: $condition) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctorRecentDiagnosis = /* GraphQL */ `
  mutation UpdateDoctorRecentDiagnosis(
    $input: UpdateDoctorRecentDiagnosisInput!
    $condition: ModelDoctorRecentDiagnosisConditionInput
  ) {
    updateDoctorRecentDiagnosis(input: $input, condition: $condition) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctorRecentDiagnosis = /* GraphQL */ `
  mutation DeleteDoctorRecentDiagnosis(
    $input: DeleteDoctorRecentDiagnosisInput!
    $condition: ModelDoctorRecentDiagnosisConditionInput
  ) {
    deleteDoctorRecentDiagnosis(input: $input, condition: $condition) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTemplateLabImage = /* GraphQL */ `
  mutation CreateTemplateLabImage(
    $input: CreateTemplateLabImageInput!
    $condition: ModelTemplateLabImageConditionInput
  ) {
    createTemplateLabImage(input: $input, condition: $condition) {
      id
      templateID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplateLabImage = /* GraphQL */ `
  mutation UpdateTemplateLabImage(
    $input: UpdateTemplateLabImageInput!
    $condition: ModelTemplateLabImageConditionInput
  ) {
    updateTemplateLabImage(input: $input, condition: $condition) {
      id
      templateID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplateLabImage = /* GraphQL */ `
  mutation DeleteTemplateLabImage(
    $input: DeleteTemplateLabImageInput!
    $condition: ModelTemplateLabImageConditionInput
  ) {
    deleteTemplateLabImage(input: $input, condition: $condition) {
      id
      templateID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDoctorMedicine = /* GraphQL */ `
  mutation CreateDoctorMedicine(
    $input: CreateDoctorMedicineInput!
    $condition: ModelDoctorMedicineConditionInput
  ) {
    createDoctorMedicine(input: $input, condition: $condition) {
      id
      doctorID
      medicineID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctorMedicine = /* GraphQL */ `
  mutation UpdateDoctorMedicine(
    $input: UpdateDoctorMedicineInput!
    $condition: ModelDoctorMedicineConditionInput
  ) {
    updateDoctorMedicine(input: $input, condition: $condition) {
      id
      doctorID
      medicineID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctorMedicine = /* GraphQL */ `
  mutation DeleteDoctorMedicine(
    $input: DeleteDoctorMedicineInput!
    $condition: ModelDoctorMedicineConditionInput
  ) {
    deleteDoctorMedicine(input: $input, condition: $condition) {
      id
      doctorID
      medicineID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createRecentDoctorMedicinePrescription = /* GraphQL */ `
  mutation CreateRecentDoctorMedicinePrescription(
    $input: CreateRecentDoctorMedicinePrescriptionInput!
    $condition: ModelRecentDoctorMedicinePrescriptionConditionInput
  ) {
    createRecentDoctorMedicinePrescription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRecentDoctorMedicinePrescription = /* GraphQL */ `
  mutation UpdateRecentDoctorMedicinePrescription(
    $input: UpdateRecentDoctorMedicinePrescriptionInput!
    $condition: ModelRecentDoctorMedicinePrescriptionConditionInput
  ) {
    updateRecentDoctorMedicinePrescription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRecentDoctorMedicinePrescription = /* GraphQL */ `
  mutation DeleteRecentDoctorMedicinePrescription(
    $input: DeleteRecentDoctorMedicinePrescriptionInput!
    $condition: ModelRecentDoctorMedicinePrescriptionConditionInput
  ) {
    deleteRecentDoctorMedicinePrescription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      medicineID
      selectedMeasure
      take
      indication
      duration
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRecentDoctorLabImagePrescription = /* GraphQL */ `
  mutation CreateRecentDoctorLabImagePrescription(
    $input: CreateRecentDoctorLabImagePrescriptionInput!
    $condition: ModelRecentDoctorLabImagePrescriptionConditionInput
  ) {
    createRecentDoctorLabImagePrescription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRecentDoctorLabImagePrescription = /* GraphQL */ `
  mutation UpdateRecentDoctorLabImagePrescription(
    $input: UpdateRecentDoctorLabImagePrescriptionInput!
    $condition: ModelRecentDoctorLabImagePrescriptionConditionInput
  ) {
    updateRecentDoctorLabImagePrescription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRecentDoctorLabImagePrescription = /* GraphQL */ `
  mutation DeleteRecentDoctorLabImagePrescription(
    $input: DeleteRecentDoctorLabImagePrescriptionInput!
    $condition: ModelRecentDoctorLabImagePrescriptionConditionInput
  ) {
    deleteRecentDoctorLabImagePrescription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTemplateAnalisis = /* GraphQL */ `
  mutation CreateTemplateAnalisis(
    $input: CreateTemplateAnalisisInput!
    $condition: ModelTemplateAnalisisConditionInput
  ) {
    createTemplateAnalisis(input: $input, condition: $condition) {
      id
      templateID
      analisisID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplateAnalisis = /* GraphQL */ `
  mutation UpdateTemplateAnalisis(
    $input: UpdateTemplateAnalisisInput!
    $condition: ModelTemplateAnalisisConditionInput
  ) {
    updateTemplateAnalisis(input: $input, condition: $condition) {
      id
      templateID
      analisisID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplateAnalisis = /* GraphQL */ `
  mutation DeleteTemplateAnalisis(
    $input: DeleteTemplateAnalisisInput!
    $condition: ModelTemplateAnalisisConditionInput
  ) {
    deleteTemplateAnalisis(input: $input, condition: $condition) {
      id
      templateID
      analisisID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAnalisis = /* GraphQL */ `
  mutation CreateAnalisis(
    $input: CreateAnalisisInput!
    $condition: ModelAnalisisConditionInput
  ) {
    createAnalisis(input: $input, condition: $condition) {
      id
      analisisName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAnalisis = /* GraphQL */ `
  mutation UpdateAnalisis(
    $input: UpdateAnalisisInput!
    $condition: ModelAnalisisConditionInput
  ) {
    updateAnalisis(input: $input, condition: $condition) {
      id
      analisisName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnalisis = /* GraphQL */ `
  mutation DeleteAnalisis(
    $input: DeleteAnalisisInput!
    $condition: ModelAnalisisConditionInput
  ) {
    deleteAnalisis(input: $input, condition: $condition) {
      id
      analisisName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientActiveDisease = /* GraphQL */ `
  mutation CreateClientActiveDisease(
    $input: CreateClientActiveDiseaseInput!
    $condition: ModelClientActiveDiseaseConditionInput
  ) {
    createClientActiveDisease(input: $input, condition: $condition) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientActiveDisease = /* GraphQL */ `
  mutation UpdateClientActiveDisease(
    $input: UpdateClientActiveDiseaseInput!
    $condition: ModelClientActiveDiseaseConditionInput
  ) {
    updateClientActiveDisease(input: $input, condition: $condition) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientActiveDisease = /* GraphQL */ `
  mutation DeleteClientActiveDisease(
    $input: DeleteClientActiveDiseaseInput!
    $condition: ModelClientActiveDiseaseConditionInput
  ) {
    deleteClientActiveDisease(input: $input, condition: $condition) {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientHistory = /* GraphQL */ `
  mutation CreateClientHistory(
    $input: CreateClientHistoryInput!
    $condition: ModelClientHistoryConditionInput
  ) {
    createClientHistory(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      treatment
      endModifyDate
      authorizationNumber
      description
      authorizationDetail
      personalPathologicalHistory
      familyPathologicalHistory
      physicalTest
      createdDate
      insuranceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientHistory = /* GraphQL */ `
  mutation UpdateClientHistory(
    $input: UpdateClientHistoryInput!
    $condition: ModelClientHistoryConditionInput
  ) {
    updateClientHistory(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      treatment
      endModifyDate
      authorizationNumber
      description
      authorizationDetail
      personalPathologicalHistory
      familyPathologicalHistory
      physicalTest
      createdDate
      insuranceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientHistory = /* GraphQL */ `
  mutation DeleteClientHistory(
    $input: DeleteClientHistoryInput!
    $condition: ModelClientHistoryConditionInput
  ) {
    deleteClientHistory(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      treatment
      endModifyDate
      authorizationNumber
      description
      authorizationDetail
      personalPathologicalHistory
      familyPathologicalHistory
      physicalTest
      createdDate
      insuranceID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientNotes = /* GraphQL */ `
  mutation CreateClientNotes(
    $input: CreateClientNotesInput!
    $condition: ModelClientNotesConditionInput
  ) {
    createClientNotes(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      noteBody
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientNotes = /* GraphQL */ `
  mutation UpdateClientNotes(
    $input: UpdateClientNotesInput!
    $condition: ModelClientNotesConditionInput
  ) {
    updateClientNotes(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      noteBody
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientNotes = /* GraphQL */ `
  mutation DeleteClientNotes(
    $input: DeleteClientNotesInput!
    $condition: ModelClientNotesConditionInput
  ) {
    deleteClientNotes(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      noteBody
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientProcedureSurgery = /* GraphQL */ `
  mutation CreateClientProcedureSurgery(
    $input: CreateClientProcedureSurgeryInput!
    $condition: ModelClientProcedureSurgeryConditionInput
  ) {
    createClientProcedureSurgery(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      description
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientProcedureSurgery = /* GraphQL */ `
  mutation UpdateClientProcedureSurgery(
    $input: UpdateClientProcedureSurgeryInput!
    $condition: ModelClientProcedureSurgeryConditionInput
  ) {
    updateClientProcedureSurgery(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      description
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientProcedureSurgery = /* GraphQL */ `
  mutation DeleteClientProcedureSurgery(
    $input: DeleteClientProcedureSurgeryInput!
    $condition: ModelClientProcedureSurgeryConditionInput
  ) {
    deleteClientProcedureSurgery(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      title
      description
      endModifyDate
      createdDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      refID
      title
      subTitle
      stripePlanKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      refID
      title
      subTitle
      stripePlanKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      refID
      title
      subTitle
      stripePlanKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDoctorStripeCustomerSubscription = /* GraphQL */ `
  mutation CreateDoctorStripeCustomerSubscription(
    $input: CreateDoctorStripeCustomerSubscriptionInput!
    $condition: ModelDoctorStripeCustomerSubscriptionConditionInput
  ) {
    createDoctorStripeCustomerSubscription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      currentPlanID
      stripeCustomerID
      stripeSubscriptionID
      defaultCard
      nextPaymentDate
      cancelAtPeriodEnd
      trialsEndsAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctorStripeCustomerSubscription = /* GraphQL */ `
  mutation UpdateDoctorStripeCustomerSubscription(
    $input: UpdateDoctorStripeCustomerSubscriptionInput!
    $condition: ModelDoctorStripeCustomerSubscriptionConditionInput
  ) {
    updateDoctorStripeCustomerSubscription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      currentPlanID
      stripeCustomerID
      stripeSubscriptionID
      defaultCard
      nextPaymentDate
      cancelAtPeriodEnd
      trialsEndsAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctorStripeCustomerSubscription = /* GraphQL */ `
  mutation DeleteDoctorStripeCustomerSubscription(
    $input: DeleteDoctorStripeCustomerSubscriptionInput!
    $condition: ModelDoctorStripeCustomerSubscriptionConditionInput
  ) {
    deleteDoctorStripeCustomerSubscription(
      input: $input
      condition: $condition
    ) {
      id
      doctorID
      currentPlanID
      stripeCustomerID
      stripeSubscriptionID
      defaultCard
      nextPaymentDate
      cancelAtPeriodEnd
      trialsEndsAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createDoctorStripeCards = /* GraphQL */ `
  mutation CreateDoctorStripeCards(
    $input: CreateDoctorStripeCardsInput!
    $condition: ModelDoctorStripeCardsConditionInput
  ) {
    createDoctorStripeCards(input: $input, condition: $condition) {
      id
      DoctorID
      cardLastDigits
      stripePaymentMethodID
      defaultCard
      cardBrand
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateDoctorStripeCards = /* GraphQL */ `
  mutation UpdateDoctorStripeCards(
    $input: UpdateDoctorStripeCardsInput!
    $condition: ModelDoctorStripeCardsConditionInput
  ) {
    updateDoctorStripeCards(input: $input, condition: $condition) {
      id
      DoctorID
      cardLastDigits
      stripePaymentMethodID
      defaultCard
      cardBrand
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteDoctorStripeCards = /* GraphQL */ `
  mutation DeleteDoctorStripeCards(
    $input: DeleteDoctorStripeCardsInput!
    $condition: ModelDoctorStripeCardsConditionInput
  ) {
    deleteDoctorStripeCards(input: $input, condition: $condition) {
      id
      DoctorID
      cardLastDigits
      stripePaymentMethodID
      defaultCard
      cardBrand
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHospital = /* GraphQL */ `
  mutation CreateHospital(
    $input: CreateHospitalInput!
    $condition: ModelHospitalConditionInput
  ) {
    createHospital(input: $input, condition: $condition) {
      id
      name
      type
      province
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateHospital = /* GraphQL */ `
  mutation UpdateHospital(
    $input: UpdateHospitalInput!
    $condition: ModelHospitalConditionInput
  ) {
    updateHospital(input: $input, condition: $condition) {
      id
      name
      type
      province
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteHospital = /* GraphQL */ `
  mutation DeleteHospital(
    $input: DeleteHospitalInput!
    $condition: ModelHospitalConditionInput
  ) {
    deleteHospital(input: $input, condition: $condition) {
      id
      name
      type
      province
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHospitalDoctor = /* GraphQL */ `
  mutation CreateHospitalDoctor(
    $input: CreateHospitalDoctorInput!
    $condition: ModelHospitalDoctorConditionInput
  ) {
    createHospitalDoctor(input: $input, condition: $condition) {
      id
      doctorID
      hospitalID
      lastWaitingListID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateHospitalDoctor = /* GraphQL */ `
  mutation UpdateHospitalDoctor(
    $input: UpdateHospitalDoctorInput!
    $condition: ModelHospitalDoctorConditionInput
  ) {
    updateHospitalDoctor(input: $input, condition: $condition) {
      id
      doctorID
      hospitalID
      lastWaitingListID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteHospitalDoctor = /* GraphQL */ `
  mutation DeleteHospitalDoctor(
    $input: DeleteHospitalDoctorInput!
    $condition: ModelHospitalDoctorConditionInput
  ) {
    deleteHospitalDoctor(input: $input, condition: $condition) {
      id
      doctorID
      hospitalID
      lastWaitingListID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createHospitalDoctorCliente = /* GraphQL */ `
  mutation CreateHospitalDoctorCliente(
    $input: CreateHospitalDoctorClienteInput!
    $condition: ModelHospitalDoctorClienteConditionInput
  ) {
    createHospitalDoctorCliente(input: $input, condition: $condition) {
      id
      clientID
      hospitalDoctorID
      lastHealthInsurranceID
      clientStatus
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateHospitalDoctorCliente = /* GraphQL */ `
  mutation UpdateHospitalDoctorCliente(
    $input: UpdateHospitalDoctorClienteInput!
    $condition: ModelHospitalDoctorClienteConditionInput
  ) {
    updateHospitalDoctorCliente(input: $input, condition: $condition) {
      id
      clientID
      hospitalDoctorID
      lastHealthInsurranceID
      clientStatus
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteHospitalDoctorCliente = /* GraphQL */ `
  mutation DeleteHospitalDoctorCliente(
    $input: DeleteHospitalDoctorClienteInput!
    $condition: ModelHospitalDoctorClienteConditionInput
  ) {
    deleteHospitalDoctorCliente(input: $input, condition: $condition) {
      id
      clientID
      hospitalDoctorID
      lastHealthInsurranceID
      clientStatus
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createWaitList = /* GraphQL */ `
  mutation CreateWaitList(
    $input: CreateWaitListInput!
    $condition: ModelWaitListConditionInput
  ) {
    createWaitList(input: $input, condition: $condition) {
      id
      hospitalDoctorID
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateWaitList = /* GraphQL */ `
  mutation UpdateWaitList(
    $input: UpdateWaitListInput!
    $condition: ModelWaitListConditionInput
  ) {
    updateWaitList(input: $input, condition: $condition) {
      id
      hospitalDoctorID
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteWaitList = /* GraphQL */ `
  mutation DeleteWaitList(
    $input: DeleteWaitListInput!
    $condition: ModelWaitListConditionInput
  ) {
    deleteWaitList(input: $input, condition: $condition) {
      id
      hospitalDoctorID
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createWaitingListItem = /* GraphQL */ `
  mutation CreateWaitingListItem(
    $input: CreateWaitingListItemInput!
    $condition: ModelWaitingListItemConditionInput
  ) {
    createWaitingListItem(input: $input, condition: $condition) {
      id
      waitingListID
      clientID
      status
      clientHealthInsurrance
      positionNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateWaitingListItem = /* GraphQL */ `
  mutation UpdateWaitingListItem(
    $input: UpdateWaitingListItemInput!
    $condition: ModelWaitingListItemConditionInput
  ) {
    updateWaitingListItem(input: $input, condition: $condition) {
      id
      waitingListID
      clientID
      status
      clientHealthInsurrance
      positionNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteWaitingListItem = /* GraphQL */ `
  mutation DeleteWaitingListItem(
    $input: DeleteWaitingListItemInput!
    $condition: ModelWaitingListItemConditionInput
  ) {
    deleteWaitingListItem(input: $input, condition: $condition) {
      id
      waitingListID
      clientID
      status
      clientHealthInsurrance
      positionNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientInsurance = /* GraphQL */ `
  mutation CreateClientInsurance(
    $input: CreateClientInsuranceInput!
    $condition: ModelClientInsuranceConditionInput
  ) {
    createClientInsurance(input: $input, condition: $condition) {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientInsurance = /* GraphQL */ `
  mutation UpdateClientInsurance(
    $input: UpdateClientInsuranceInput!
    $condition: ModelClientInsuranceConditionInput
  ) {
    updateClientInsurance(input: $input, condition: $condition) {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientInsurance = /* GraphQL */ `
  mutation DeleteClientInsurance(
    $input: DeleteClientInsuranceInput!
    $condition: ModelClientInsuranceConditionInput
  ) {
    deleteClientInsurance(input: $input, condition: $condition) {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createInsurance = /* GraphQL */ `
  mutation CreateInsurance(
    $input: CreateInsuranceInput!
    $condition: ModelInsuranceConditionInput
  ) {
    createInsurance(input: $input, condition: $condition) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInsurance = /* GraphQL */ `
  mutation UpdateInsurance(
    $input: UpdateInsuranceInput!
    $condition: ModelInsuranceConditionInput
  ) {
    updateInsurance(input: $input, condition: $condition) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsurance = /* GraphQL */ `
  mutation DeleteInsurance(
    $input: DeleteInsuranceInput!
    $condition: ModelInsuranceConditionInput
  ) {
    deleteInsurance(input: $input, condition: $condition) {
      id
      name
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClientClaim = /* GraphQL */ `
  mutation CreateClientClaim(
    $input: CreateClientClaimInput!
    $condition: ModelClientClaimConditionInput
  ) {
    createClientClaim(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      healthInsuranceID
      diagnostic
      authorization
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClientClaim = /* GraphQL */ `
  mutation UpdateClientClaim(
    $input: UpdateClientClaimInput!
    $condition: ModelClientClaimConditionInput
  ) {
    updateClientClaim(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      healthInsuranceID
      diagnostic
      authorization
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClientClaim = /* GraphQL */ `
  mutation DeleteClientClaim(
    $input: DeleteClientClaimInput!
    $condition: ModelClientClaimConditionInput
  ) {
    deleteClientClaim(input: $input, condition: $condition) {
      id
      clientHospitalDoctorID
      healthInsuranceID
      diagnostic
      authorization
      date
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createClaimProcess = /* GraphQL */ `
  mutation CreateClaimProcess(
    $input: CreateClaimProcessInput!
    $condition: ModelClaimProcessConditionInput
  ) {
    createClaimProcess(input: $input, condition: $condition) {
      id
      claimID
      name
      code
      claimed
      difference
      authorized
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClaimProcess = /* GraphQL */ `
  mutation UpdateClaimProcess(
    $input: UpdateClaimProcessInput!
    $condition: ModelClaimProcessConditionInput
  ) {
    updateClaimProcess(input: $input, condition: $condition) {
      id
      claimID
      name
      code
      claimed
      difference
      authorized
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClaimProcess = /* GraphQL */ `
  mutation DeleteClaimProcess(
    $input: DeleteClaimProcessInput!
    $condition: ModelClaimProcessConditionInput
  ) {
    deleteClaimProcess(input: $input, condition: $condition) {
      id
      claimID
      name
      code
      claimed
      difference
      authorized
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

import {
  CardContent,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FC, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/header/Header';
import { UserContextType } from '../context/type';
import { UserContext } from '../context/UserContext';
import {
  ContainerCenter,
  StyledCardHeader,
  StyledCard,
  StyledInput,
  TitleTypography,
  TrialButton,
  ViewButton,
} from '../share/style';
import { encryptMessage, useQuery } from '../share/utils';
import useTolt from '../hooks/useTolt';

interface Props {
  isNormalNavBar: Boolean;
}

type ErrorHandlerType = {
  key: string;
  message: string;
  hasError: boolean;
};

interface LocationState {
  code: string;
}

const Password: FC<Props> = ({ isNormalNavBar }) => {
  const history = useHistory();
  let query = useQuery();
  const { updateUserPassword, setAlertMessage, user } = useContext(
    UserContext
  ) as UserContextType;
  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confPassword, setConfPassword] = useState<string>('');
  const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [errorHandler, setErrorHandler] = useState<Array<ErrorHandlerType>>([]);
  const { getRefStored, removeRefStored } = useTolt('ref');

  const onChangePasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPassword(event.target.value);
  };

  const handlePasswordValidations = () => {
    if (password.length < 8 || password === '') {
      const currentError = errorHandler.findIndex(
        (error) => error.key === 'password'
      );
      if (currentError === -1) {
        const error = {
          key: 'password',
          message: 'La contraseña debe tener al menos 8 caracteres',
          hasError: true,
        };

        setErrorHandler((prevErrorHandler) => [...prevErrorHandler, error]);
      }
    } else {
      const currentErrors = errorHandler.filter(
        (error) => error.key !== 'password'
      );

      setErrorHandler(currentErrors);
    }

    if (confPassword === '' || confPassword !== password) {
      const currentError = errorHandler.findIndex(
        (error) => error.key === 'confPassword'
      );
      if (currentError === -1) {
        const error = {
          key: 'confPassword',
          message: 'Las contraseñas no coinciden',
          hasError: true,
        };

        setErrorHandler((prevErrorHandler) => [...prevErrorHandler, error]);
      }
    } else {
      const currentErrors = errorHandler.filter(
        (error) => error.key !== 'confPassword'
      );

      setErrorHandler(currentErrors);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleClickShowConfPassword = () => {
    setShowConfPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownConfPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onChangeConfPasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfPassword(event.target.value);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCreatePassword = async () => {
    try {
      setIsSubmited(true);
      if (errorHandler.length === 0) {
        if (!query.get('email')) {
          const response = await updateUserPassword(password);

          if (!response) {
            setAlertMessage({
              message: 'Ocurrió un error al crear la contraseña',
              severity: 'error',
            });
            setIsSubmited(false);
            return;
          }

          removeRefStored();
          const messageURl = encryptMessage({ user: user.email, password });
          window.open(
            `${process.env.REACT_APP_PULSEARA_VERIFY_ROUTE}${messageURl} `
          );
          history.push('/');
        }
      } else {
        setIsSubmited(false);
      }
    } catch (error) {
      setIsSubmited(false);
    }
  };

  const getError = (key: string) => {
    if (errorHandler.length === 0) return null;

    const error = errorHandler.find((error) => error.key === key);

    if (!error) return null;

    return error?.message;
  };

  useEffect(() => {
    handlePasswordValidations();
  }, [password, confPassword]);

  return (
    <div>
      <Header isNormalNavBar={isNormalNavBar} />
      <ContainerCenter
        style={{
          marginTop: '100px',
        }}
      >
        <TitleTypography>Un paso más...</TitleTypography>
        <StyledCard>
          <StyledCardHeader title="Crea una contraseña" />
          <CardContent className="flex flex-col">
            {query.get('email') && (
              <StyledInput
                value={name}
                variant="outlined"
                type={'text'}
                onChange={onChangeName}
                id="name"
                name="name"
                fullWidth
                label="Nombre completo"
                placeholder="Nombre completo"
              />
            )}
            <StyledInput
              style={{ maxWidth: '100%' }}
              tabIndex={1}
              value={password}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              onChange={onChangePasswordHandler}
              id="password"
              name="password"
              fullWidth
              label="Nueva contraseña"
              placeholder="Nueva contraseña"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle new password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!password && !!getError('password')}
              helperText={!!password && getError('password')}
            />
            <StyledInput
              style={{ maxWidth: '100%' }}
              tabIndex={2}
              value={confPassword}
              variant="outlined"
              type={showConfPassword ? 'text' : 'password'}
              onChange={onChangeConfPasswordHandler}
              id="confPassword"
              name="confPassword"
              fullWidth
              placeholder="Confirma tu nueva contraseña"
              label="Confirma tu nueva contraseña"
              error={!!confPassword && !!getError('confPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle confirmation password visibility"
                      onClick={handleClickShowConfPassword}
                      onMouseDown={handleMouseDownConfPassword}
                      edge="end"
                    >
                      {showConfPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={!!confPassword && getError('confPassword')}
            />
            <TrialButton
              style={{ maxWidth: '100%' }}
              tabIndex={3}
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCreatePassword}
              disabled={errorHandler.length !== 0 || isSubmited}
            >
              <span>Crear Contraseña</span>
              {isSubmited ? <CircularProgress size={20} /> : null}
            </TrialButton>
            <ViewButton
              style={{ maxWidth: '100%' }}
              size="large"
              color="primary"
              fullWidth
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </ViewButton>
          </CardContent>
        </StyledCard>
      </ContainerCenter>
    </div>
  );
};

export default Password;

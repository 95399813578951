import React, { ChangeEvent } from 'react';

import './Selector.style.css'

interface Props {
	id: string;
	label?: string;
	value: string;
	disabled?: boolean;
	values: string[];
	onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const Selector: React.FC<Props> = ({ id, label = '', value, onChange, values: specialties }) => (
	<div className="selector-container">
		<select
			id={id}
			value={value}
			defaultValue='Selecciona tu ocupación'
			onChange={onChange}
			placeholder='Selecciona tu ocupación'
			className="selector-container__select"
		>
			<option disabled selected value="">Selecciona tu ocupación</option>
			{specialties.map((s) => (
				<option key={s} value={s}>
					{s}
				</option>
			))}
		</select>
	</div>
);

export default Selector;

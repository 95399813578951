import React, { FC } from 'react';

import { Card, CardHeader, CardContent, CardActions, Grid, Button, Typography } from '@material-ui/core';
import './PriceCard.style.css';

interface props {
    title: string;
    tag?: string;
    children?: React.ReactNode;
    price: string;
    priceDescription: string;
    goToRegisterHandler: () => void;
}

const PriceCard: FC<props> = ({ title, tag, children, price, priceDescription, goToRegisterHandler }) => {
    return (
        <Card className='card'>
            <CardHeader component={() => {
                return (
                    <Grid className='card__header' container>
                        <h6 className='card__header_title' >{title}</h6>
                        {tag && <span className='card__header_title__tag' >{tag}</span>}
                    </Grid>
                );
            }} />
            <CardContent className='card__content'>
                {children}
            </CardContent>
            <CardActions>
                <Grid container className='card__action'>
                    <div>
                        <Typography className='card__action_price' >{price}</Typography>
                        <span className='card__action_price_description' >{priceDescription}</span>
                    </div>
                    <Button className='actions__button' variant='contained' onClick={goToRegisterHandler} >Probar gratis</Button>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default PriceCard;
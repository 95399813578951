import styled from 'styled-components'
import { createTheme, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 745,
			md: 1075,
			lg: 1201,
			xl: 2500,
		},
	},
})
export const FooterContainer = withStyles({
	root: {
		display: 'flex',
		bottom: 0,
		width: '100%',
		padding: '1% 10px',
		backgroundColor: 'rgba(248, 248, 249, 1)',
		borderTop: '1px solid rgba(212, 216, 219, 1)',
		'@media (max-width: 745px)': {
			padding: '5% 10px',
		},
		marginTop: '45px',
	},
})(Grid) as typeof Grid

export const LogoContainer = withStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
})(Grid) as typeof Grid

export const WhatsappContainer = withStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#1565c0',
	},
})(Grid) as typeof Grid

export const StyledWhatsAppIcon = withStyles({
	root: {
		marginRight: theme.spacing(0.5),
	},
})(WhatsAppIcon) as typeof WhatsAppIcon

export const WhatsappLink = styled.a`
	color: #1565c0;
	text-decoration: none;
	font-family: Roboto;
	font-weight: 400;
	&:visited {
		color: #1565c0;
	}
	&:hover {
		color: #1565c0;
	}
	&:link {
		color: #1565c0;
	}
`

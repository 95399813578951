import { FC, useState } from 'react'
import ReactPlayer from 'react-player'
import Header from '../components/header/Header'
import Flag from '../components/svg/Flag'
import VideoPlayer from '../components/videoPlayer/VideoPlayer'
import {
	RegisterBeforeContainer,
	TrialButton,
	RegisterBeforeDescriptionText,
	RegisterBeforeTitle,
} from '../share/style'
import { URL_VIDEO } from '../share/constant'

interface Props {
	isNormalNavBar: Boolean
}

const RegisterBefore: FC<Props> = ({ isNormalNavBar }) => {
	const [open, setOpen] = useState<boolean>(false)
	const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false)

	const openAndPlayVideo = () => {
		setOpen(true)
		setIsPlayingVideo(true)
	}

	const closeAndStopVideo = () => {
		setOpen(false)
		setIsPlayingVideo(false)
	}

	const handleOpenVideo = () => {
		openAndPlayVideo()
	}
	return (
		<div>
			{open && (
				<VideoPlayer open={open} handleClose={closeAndStopVideo}>
					<ReactPlayer
						className='react-player'
						url={URL_VIDEO}
						loop={true}
						playing={isPlayingVideo}
						controls={true}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						style={{ maxWidth: '870.3px' }}
						height={'501.2px'}
					/>
				</VideoPlayer>
			)}
			<Header isNormalNavBar={isNormalNavBar} />
			<RegisterBeforeContainer>
				<Flag />
				<RegisterBeforeTitle>Ya te habías registrado</RegisterBeforeTitle>
				<RegisterBeforeDescriptionText>
					Inicia sesión en tu perfil dando click al siguiente botón  
				</RegisterBeforeDescriptionText>
				<TrialButton
					component={'a'}
					variant='contained'
					color='primary'
					href={process.env.REACT_APP_APP_PULSEARA_URL}
					target='_blank'
					style={{ maxWidth: '160px', height: '40px', marginTop: '10px' }}
					size='large'
					> Iniciar sesión</TrialButton>
			</RegisterBeforeContainer>
		</div>
	)
}

export default RegisterBefore

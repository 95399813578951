import React, { FC, useContext, useState, useEffect } from 'react';
import { AIDiagnosticExamples, AIDiagnosticExamplesTypes, AISection } from '../components/AI/types';
import { AIButton, AICardActions, AICardContainer, AICardContent, AICardHeader, AIContainer, AITextField, CustomParagraph, CustomTypography, ReportContainer, StyledChip, SuggestionContainer } from '../components/AI/styles';
import Header from '../components/header/Header';
import { AIContextProps, NavigationContextType, Tabs, UserContextType } from '../context/type';
import { AiContext } from '../context/ai-context';
import { AIDiagnosisSuggestion, AIMedicalPlanSuggestion, AIPatienDiagnostic } from '../share/ai/types';
import { CircularProgress, TextField } from '@material-ui/core';
import AIReport from '../components/AI/AIReport';
import AIDiagnosticReport from '../components/AI/AIDiagnosticReport';
import Footer from '../components/footer/Footer';
import { ContainerCenter, StyledInput } from '../share/style';
import Banner from '../components/AI/Banner/Banner';
import {
  NavigationContext,
} from '../context/NavigationContext';
import Modal from '../components/Modal/Modal';
import Selector from '../components/Selector/Selector';
import {
  UserContext,
} from '../context/UserContext';
import { useSpring, animated, config, useTransition } from '@react-spring/web';

import './styles/AI.style.css';

interface Props {
	isNormalNavBar: Boolean
}

const AI:FC<Props> = ({
    isNormalNavBar
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const {
      setActiveTab,
    } = useContext(NavigationContext) as NavigationContextType;
    const { setAiSection, setIAPrompt, clearIASuggestions, loading, aiSuggestions, aiSection } = useContext(
        AiContext
    ) as AIContextProps;
	  const { setAlertMessage, registerBroadcaster } = useContext(UserContext) as UserContextType;
    const [normalNavBar, setNormalNavBar] = useState<Boolean>(isNormalNavBar);
    const [showGetEmailModal, setShowGetEmailModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [specialty, setSpecialty] = useState<string>('');
    const [savingClient, setSavingClient] = useState<boolean>(false);
    const [errors, setErrors] = useState({
      email: '',
      specialty: '',
    });

    const onClickExample = (value: AIDiagnosticExamplesTypes) => {
        if (value === 'chest_pain') {
          setInputValue(AIDiagnosticExamples.chest_pain);
        }
        if (value === 'inverted_t_waves') {
          setInputValue(AIDiagnosticExamples.inverted_t_waves);
        }
        if (value === 'st_elevation_hypertensive') {
          setInputValue(AIDiagnosticExamples.st_elevation_hypertensive);
        }
    };

    const dataToPrompt: AIPatienDiagnostic = {
        clinicPresentation: inputValue,
    };

    const onClickHandler = (section: AISection ) => {
        clearIASuggestions();
        setAiSection(section);
        setIAPrompt(dataToPrompt);
        
        const executions = Number(localStorage.getItem('executions') || 0);
        const isPreRegisterEmail = localStorage.getItem('pre-register-email');
        if (!isPreRegisterEmail && executions === 2) {
          setShowGetEmailModal(true);
        } 

        if (executions < 2) {
          localStorage.setItem('executions', String(executions + 1));
        }
    }

    const renderReport = () => {
        if (aiSection === 'diagnosis' && !!aiSuggestions) {
          const diagnosticData = aiSuggestions as AIDiagnosisSuggestion;
          return (
            <AIDiagnosticReport
              diagnostics={diagnosticData?.diagnosticos}
            />
          )
        }
    
        if (aiSection === 'medical_plan' && !!aiSuggestions) {
          const medicalPlanData = aiSuggestions as AIMedicalPlanSuggestion;
          return (
            <AIReport
              description={medicalPlanData?.planMedico as string}
              treatment={
                medicalPlanData?.tratamientos?.map((t) => t.nombre).join(', ') ?? ''
              }
              study={medicalPlanData?.estudios?.map((e) => e.nombre).join(', ') ?? ''}
              medicines={
                medicalPlanData?.medicamentos?.map((m) => m.nombre).join(', ') ?? ''
              }
              diagnostics={
                medicalPlanData?.diagnosticos?.map((d) => d.nombre).join(', ') ?? ''
              }
            />
          );
        }
      };
      
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const startPoint = 50;
        
        if (scrollPosition >= startPoint) {
          setNormalNavBar(false);
        } else {
          setNormalNavBar(true);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      const isPreRegisterEmail = localStorage.getItem('pre-register-email');
      const executions = Number(localStorage.getItem('executions') || 0);
      if (!isPreRegisterEmail && executions === 2) {
        setShowGetEmailModal(true);
      }
    }, []);

    useEffect(() => {       
      setActiveTab(Tabs.AIDiagnostic);
    }, []);

    const formValidator = () => {
      let valid = true;
      const regexEmail =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

      if (!email || !regexEmail.test(email)) {
        valid = false;
        setErrors((prev) => ({
          ...prev,
          email: 'Ingresa un correo válido',
        }));
      } else {
        valid = true;
        setErrors((prev) => ({
          ...prev,
          email: '',
        }));
      }
      return valid;
    }

    const saveClient = async () => {
      try {

        if (!formValidator()) return;

        setSavingClient(true);

        const response = await registerBroadcaster(email, specialty);

        if (!response) {
          setAlertMessage({
            message: 'Ocurrió un error al guardar el cliente',
            severity: 'error',
          });
          return;
        }

        if (response === 'ok' || response === 'duplicated') {

          if(response === 'duplicated') {
            setAlertMessage({
              message: 'Ya estás registrado, gracias por usar Pulseara AI',
              severity: 'success',
            });
          } else {

            setAlertMessage({
              message: '¡Gracias por registrarte!',
              severity: 'success',
            });
  
          }

          setShowGetEmailModal(false);
          localStorage.setItem('pre-register-email', 'true');
        }
        setSavingClient(false);
        
      } catch (error) {
        setAlertMessage({
          message: 'Ocurrió un error al guardar el cliente',
          severity: 'error',
        });
        setSavingClient(false);
      }
    };
    const animatedBackground = useSpring({
      backgroundPosition: !!aiSuggestions ? '0% 145%' : '0% 55%',
      config: { duration: 500 },
      reset: true,
    });

    const textTransitions = useTransition(!!aiSuggestions, {
      from: { opacity: 0, transform: 'translateY(-20px)' },
      enter: { opacity: 1, transform: 'translateY(0)' },
      leave: { opacity: 0, transform: 'translateY(-20px)' },
      config: { duration: 500 },
    });
  

    return (
      <>
        <Modal handleClose={()=>{}} open={showGetEmailModal}>
          <h1 className='AI-popup__title'>
            Continua usando Pulseara AI gratis
          </h1>
          <p className='AI-popup__sub-title'>
            Ingresa tu correo y nombre para seguir generando sugerencias con inteligencia artificial. 
          </p>
          <TextField
								id='email'
								fullWidth
								margin='dense'
								name='email'
								type='email'
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
								placeholder='Correo electrónico'
								variant='outlined'
                className='AI-popup__input'
                error={!!errors.email}
                helperText={errors.email}
							/>
          <Selector 
            id='specialty' 
            value={specialty} 
            onChange={(e)=>setSpecialty(e.target.value)} 
            label='Especialidad' 
            values={[
            'Doctor',
            'Enfermera',
            "Estudiante de medicina",
            "Ayudante de doctor",
            "Otro"
            ]}
          />
          <AIButton
              size="small"
              color="primary"
              variant="contained"
              disabled={!email || !specialty || savingClient}
              className='AI-popup__btn'
              onClick={saveClient}
          >
              seguir usando
          </AIButton>
        </Modal>
        <animated.div
          className='AI_Container'
          style={{
            backgroundImage: 'linear-gradient(to bottom, rgba(25, 118, 210, 0.08) 50%, #FFFFFF 50%)',
            backgroundSize: '100% 120%',
            ...animatedBackground,
          }}
        >
            <Header isNormalNavBar={normalNavBar} />
            <ContainerCenter style={!!aiSuggestions ? {
              marginTop: '50px',
            }: {}} >
                <CustomTypography as={'h5'}>
                    Sugerencias médicas con inteligencia artificial
                </CustomTypography>
                <CustomTypography
                    as={'h5'}
                    style={{
                        fontSize: '16px',
                        marginTop: '6px',
                        marginBottom: '40px',
                    }}
                    subTitle
                >
                    Analizamos el historial y síntomas del paciente para sugerir Dx y plan
                    médico
                </CustomTypography>
                <SuggestionContainer className='AI-Suggestion__Container'>
                    <CustomParagraph>
                    Prueba estos ejemplos:
                    </CustomParagraph>
                    <StyledChip
                      label={'ST elevado en hipertenso'}
                      clickable
                      color="primary"
                      variant="outlined"
                      className='AI-Suggestion__Container_item'
                      onClick={() => onClickExample('st_elevation_hypertensive')}
                    />
                    <StyledChip
                      label={'Ondas T invertidas'}
                      clickable
                      color="primary"
                      variant="outlined"
                      className='AI-Suggestion__Container_item'
                      onClick={() => onClickExample('inverted_t_waves')}
                    />
                    <StyledChip
                      label={'Dolor torácico'}
                      clickable
                      color="primary"
                      variant="outlined"
                      className='AI-Suggestion__Container_item'
                      onClick={() => onClickExample('chest_pain')}
                    />
                </SuggestionContainer>
                <AICardContainer>
                    <AICardHeader title="Ingrese presentación clínica del paciente" />
                    <AICardContent>
                      <AITextField
                          placeholder="Ejemplo:  Un hombre de 50 años se presenta en el hospital con dolor en el pecho..."
                          variant="outlined"
                          value={inputValue}
                          multiline
                          rows={3}
                          onChange={(e) => setInputValue(e.target.value)}
                      />
                    </AICardContent>
                    <AICardActions>
                    <AIButton
                        size="small"
                        variant="contained"
                        disabled={!inputValue || loading}
                        customDisabled
                        onClick={() => onClickHandler('diagnosis')}
                    >
                        posibles Diagnósticos
                    </AIButton>
                    <AIButton
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={!inputValue || loading}
                        
                        onClick={() => onClickHandler('medical_plan')}
                    >
                        Generar plan médico
                    </AIButton>
                </AICardActions>
                </AICardContainer>
                { loading ? <CircularProgress style={{
                    height: '40px',
                    width: '40px',
                    marginBottom: '20px',
                }} size={20}  /> : null }
            {textTransitions(
                    (styles, item) =>
                      item && (
                            <animated.div className={'Report_Container'} style={styles}>
                               {renderReport()}
                            </animated.div> ) ) }
            </ContainerCenter>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Banner />
            <Footer containerStyles={{
              marginTop: '0px',
            }} />
            </div>
        </animated.div>
    </>
    )
};

export default AI;
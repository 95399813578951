import {
	ImageSectionContainer,
	InformationrContainer,
	FormSectionContainer,
	RegisterFormSection,
	TitleTypography,
	FormContainer,
	FormImage,
} from './style'
import formImg from '../../assets/images/formImg.png'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { useContext, useState, forwardRef } from 'react'
import { UserContext } from '../../context/UserContext'
import { CreateDoctorResponseMessageType, UserContextType } from '../../context/type'
import { StyledInput, TrialButton, ViewButton } from '../../share/style'
import { useHistory, useLocation } from 'react-router-dom'
import phoneMask from '../masks/phoneMask'
import VideoPlayer from '../videoPlayer/VideoPlayer'
import ReactPlayer from 'react-player'
import { URL_VIDEO } from '../../share/constant'
import { CircularProgress } from '@material-ui/core'
import TermsAndConditions from '../termsAndConditions/TermsAndconditions'

type Props = {
	ref: React.RefObject<HTMLDivElement>
}

const FormSection = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const history = useHistory();
	const location = useLocation();
	const { user, isInvalidEmail, handleChangeUser, handleChangeEmail, createDoctor, setAlertMessage } =
		useContext(UserContext) as UserContextType;
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [open, setOpen] = useState<boolean>(false)
	const [isPlayingVideo, setIsPlayingVideo] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false)
	const queryParams = new URLSearchParams(location.search);

	const isValidForm = () => {
        return user.email && user.name && user.phone && user.phone.length === 14;
    }
	const handleTry = async () => {
		try {
			if (isValidForm()) {
				setLoading(true)
				const isTrial = queryParams.get('isTrial') === 'false';
				const useCreditCard = queryParams.get('useCreditCard') === 'false';
				const needVerification = queryParams.get('needVerification') === 'false';
				const response = await createDoctor(user.email, !isTrial, !useCreditCard, !needVerification);

				if (response === CreateDoctorResponseMessageType.EMAIL_ALREADY_EXISTS) {
					history.push({
						pathname: '/register-before/',
					});
					return;
				};

				if (response === CreateDoctorResponseMessageType.ERROR) {
					return;
				}

				if (response === CreateDoctorResponseMessageType.NO_VALIDATION_NEEDED) {
					history.push({
						pathname: '/temp-password-confirmation',
					});
					return
				}

				if (response === CreateDoctorResponseMessageType.PASSWORD_PENDING) {
					history.push({
						pathname: '/complete-registration',
					});
					return
				}

				history.push({
					pathname: '/confirmation/',
					state: { email: user.email },
				});
			} else {
				setIsSubmitted(true)
			}
		} catch (error) {
			setAlertMessage({
				message: 'Error al crear el doctor',
				severity: 'error',
			});
			setLoading(false)

		}

	}

	const openAndPlayVideo = () => {
		setOpen(true)
		setIsPlayingVideo(true)
	}

	const closeAndStopVideo = () => {
		setOpen(false)
		setIsPlayingVideo(false)
	}

	const handleOpenVideo = () => {
		openAndPlayVideo()
	}

	return (
		<>
			{open && (
				<VideoPlayer open={open} handleClose={closeAndStopVideo}>
					<ReactPlayer
						className='react-player'
						url={URL_VIDEO}
						loop={true}
						playing={isPlayingVideo}
						controls={true}
						config={{
							youtube: {
								playerVars: { showinfo: 1 },
							},
						}}
						style={{ maxWidth: '870.3px' }}
						height={'501.2px'}
					/>
				</VideoPlayer>
			)}
			<InformationrContainer>
				<FormContainer container>
					<ImageSectionContainer item xs={6} md={6}>
						<FormImage src={formImg} alt='form-img' />
					</ImageSectionContainer>
					<FormSectionContainer item xs={6} md={6} id='form-section' ref={ref}>
						<TitleTypography>
							Ingresa al futuro de la medicina moderna
						</TitleTypography>
						<RegisterFormSection>
							<StyledInput
								id='name'
								fullWidth
								name='name'
								value={user.name}
								onChange={handleChangeUser}
								margin='dense'
								placeholder='Nombre completo*'
								variant='outlined'
								helperText={
									isSubmitted &&
									user.name === '' &&
									'El nombre completo es requerido'
								}
								error={isSubmitted && user.name === ''}
							/>
							<StyledInput
								id='email'
								fullWidth
								margin='dense'
								name='email'
								value={user.email}
								onChange={handleChangeEmail}
								type='email'
								placeholder='Correo*'
								variant='outlined'
								helperText={
									isInvalidEmail
										? 'El correo no es válido'
										: isSubmitted &&
										user.email === '' &&
										'El correo es requerido'
								}
								error={isInvalidEmail || (isSubmitted && user.email === '')}
							/>
							<StyledInput
								id='phone'
								fullWidth
								margin='dense'
								name='phone'
								value={user.phone}
								onChange={handleChangeUser}
								placeholder='Número de celular*'
								variant='outlined'
								InputProps={{ inputComponent: phoneMask as any }}
								helperText={
									isSubmitted && user.phone === '' && 'El telefono es requerido' ||
									isSubmitted && user.phone.length < 14 && 'El telefono debe tener 10 dígitos'
								}
								error={isSubmitted && user.phone === '' ||
									isSubmitted && user.phone.length < 14}
							/>
							<TrialButton
								fullWidth
								variant='contained'
								color='primary'
								size='small'
								style={{
									maxWidth: '315px',
									maxHeight: '40px',
								}}
								onClick={handleTry}
								disabled={loading}
							>
								<span>probar gratis</span>
								{loading ? <CircularProgress size={20} /> : null}

							</TrialButton>
							<TermsAndConditions />
						</RegisterFormSection>
					</FormSectionContainer>
				</FormContainer>
			</InformationrContainer>
		</>
	)
});

export default FormSection

import React, { useContext, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar } from '@material-ui/core';
import { UserContext } from '../../context/UserContext';
import { UserContextType } from '../../context/type';

const BottomAlert = () => {
	const { alertMessage, setAlertMessage } = useContext(UserContext) as UserContextType

	useEffect(() => {
		if (navigator.onLine && alertMessage?.message) {
			const alertTimer = setTimeout(function () {
				setAlertMessage({ message: null, severity: 'success' });
			}, 4000);

			return () => clearTimeout(alertTimer);
		}
	}, [alertMessage]);

	return alertMessage?.message ? (
		<>
			<Snackbar open={Boolean(alertMessage.message)} autoHideDuration={3000}>
				<Alert
					action={
						navigator.onLine && (
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setAlertMessage({ message: null, severity: 'success' });
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						)
					}
					variant="filled"
					severity={alertMessage.severity}
				>
					{alertMessage.message}
				</Alert>
			</Snackbar>
		</>
	) : null;
};

export default BottomAlert;

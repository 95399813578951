import React, { FC } from 'react';
import './DecoratorCard.style.css';


export enum Period {
    YEAR = 'YEAR',
    MONTH = 'MONTH'
}

interface props {
    children: React.ReactNode;
    period: Period;
}

const DecoratorAnualCard: FC<props> = ({
    children,
    period
}) => {
    return (
        <span className={`decorator ${period === Period.YEAR ? "year__decorator" : "month__decorator"}`}>
            <div>
                {children}
            </div>
        </span>
    );
};

export default DecoratorAnualCard;
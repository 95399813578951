import styled from 'styled-components'
import { createTheme, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const theme = createTheme();
export const HeaderContainer = withStyles({
    root: {
      position: 'sticky',
      zIndex: 100,
      top: 0,
      padding: theme.spacing(2.5),

      
    },
  })(Grid) as typeof Grid;

export const LogoContainer = withStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      columnGap: '40px',
    },
  })(Grid) as typeof Grid;

export const WhatsappContainer = withStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: '25px',
      justifyContent: 'flex-end',
      color: '#1565c0'
    },
  })(Grid) as typeof Grid;

export const StyledWhatsAppIcon = withStyles({
  root: {
      marginRight: theme.spacing(0.5)
  },
})(WhatsAppIcon) as typeof WhatsAppIcon;

export const WhatsappLink = styled.a`
  color: #1565c0;
  text-decoration: none;
  font-family: Roboto;
  font-weight: 400;
  &:visited {
    color: #1565c0;
  }
  &:hover {
    color: #1565c0;
  }
  &:link {
    color: #1565c0;
  }
`

export const StyledTypography = styled(Typography)`
      && {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      
        text-align: center;
        letter-spacing: 0.15px;

        color: #000000;
    }


    
    @media screen and (min-width: 768px) and (max-width: 1070px) {
        && {
          font-size: 12px;
      }
    }

    @media screen and (min-width: 600px) and (max-width: 767px) {
      && {
        font-size: 14px;
    }
  }

`
import { CardContent, CircularProgress, InputAdornment } from '@material-ui/core';
import { FC, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../components/header/Header'
import {
	ContainerCenter,
	StyledCard,
	StyledCardHeader,
	StyledInput,
	TitleTypography,
	TrialButton,
	ViewButton,
} from '../share/style'
import { UserContext } from '../context/UserContext'
import { UserContextType } from '../context/type'
import CustomIconButton from '../components/iconButton/CustomIconButton';


interface Props {
	isNormalNavBar: Boolean
}

interface LocationState {
	email: string;
}

const ConfirmationCode: FC<Props> = ({ isNormalNavBar }) => {
	const history = useHistory();
	const location = useLocation<LocationState>();
	const [code, setCode] = useState<string>('');
	const [isResendDisable, setIsResendDisable] = useState(true);
	const { confirmSignUp, setAlertMessage, resendConfirmationCode } = useContext(UserContext) as UserContextType;
	const [disableButton, setDisableButton] = useState<boolean>(true);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);

	useEffect(() => {
		if (isResendDisable) {
			const resendTimeout = setTimeout(() => {
				setIsResendDisable(false);
			}, 60000);

			return () => {
				clearTimeout(resendTimeout);
			};
		}
	}, [isResendDisable]);
		
	const onChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCode(event.target.value)
		if (event.target.value.length === 6) {
			setDisableButton(false)
		} else {
			setDisableButton(true)
		}
	}

	const confirmCodeHandler = async () => {
		try {
			setDisableButton(true);
			setIsSubmited(true);
			const { email } = location.state;
			await confirmSignUp(email, code);
			history.push({
				pathname: '/complete-registration',
			});
		} catch (error) {
			setAlertMessage({
				message: 'El código ingresado es incorrecto',
				severity: 'error',
			});
		} finally {
			setDisableButton(false);
			setIsSubmited(false);
		}
	}

	const resendCodeHandler = async () => {
		const { email } = location.state;
		await resendConfirmationCode(email);
	};

	return (
		<div>
			<Header isNormalNavBar={isNormalNavBar} />
			<ContainerCenter style={{
				marginTop: "100px"
			}}>
				<TitleTypography>Confirma tu correo</TitleTypography>
				<StyledCard>
					<StyledCardHeader
						title='Un código fue enviado a tu correo'
						subheader='Revisa tu carpeta de spam si no lo ves.'
					/>
					<CardContent className='flex flex-col'>
						<StyledInput
							style={{ maxWidth: '100%' }}
							InputProps={{
								inputProps: {
								  step: '0.1',
								  maxLength: 6,
								},
								endAdornment: (
									<InputAdornment position="end">
										<CustomIconButton
											aria-label="resend code"
											onClick={resendCodeHandler}
											onMouseOver={event => event.preventDefault()}
											disabled={isResendDisable}
											text="Reenviar"
										/>
									</InputAdornment>
								),
							}}
							value={code}
							variant='outlined'
							type={'text'}
							onChange={onChangeCode}
							id='code'
							name='code'
							fullWidth
							label='Ingresa el código*'
							placeholder='Ingresa el código*'
						/>
						<TrialButton
							style={{ maxWidth: '100%' }}
							size='large'
							variant='contained'
							color='primary'
							fullWidth
							onClick={confirmCodeHandler}
							disabled={disableButton}
						>
							Confirmar
							{ isSubmited ? <CircularProgress size={20}  /> : null }
						</TrialButton>
						<ViewButton
							style={{ maxWidth: '100%' }}
							size='large'
							color='primary'
							fullWidth
							onClick={() => {
								history.goBack()
							}}
						>
							Cancelar
						</ViewButton>
					</CardContent>
				</StyledCard>
			</ContainerCenter>
		</div>
	)
}

export default ConfirmationCode

import React, { useState, useRef } from 'react'
import './App.css'
import Home from './pages/Home'
import Password from './pages/Password'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import RegisterBefore from './pages/RegisterBefore'
import ConfirmationCode from './pages/ConfirmationCode'
import BottomAlert from './components/bottomAlert/BottomAlert'
import Register from './pages/Register'
import AI from './pages/AI'
import TempPassword from './pages/TempPassword'

function App() {
	const [isNormalNavBar, setIsNormalNavBar] = useState<boolean>(true)
	const registerFormRef = useRef<HTMLDivElement>(null)

	const onScroll = (e: React.UIEvent<HTMLElement>): void => {
		const currentScrollY = e.currentTarget.scrollTop || window.scrollY
		if (currentScrollY > 100) {
			setIsNormalNavBar(false)
		} else {
			setIsNormalNavBar(true)
		}
	}


	const goToRegisterHandler = () => {
		const formRef = registerFormRef.current;
		if (formRef) {
			formRef.scrollIntoView({ behavior: 'smooth' });
		}
	}

	return (
		<div style={{
			height: '100vh',
			display: 'grid',
		}}>
			<Router>
				<Switch>
					<Route path='/register-before'>
						<RegisterBefore isNormalNavBar={true} />
					</Route>
					<Route path='/complete-registration'>
						<Password isNormalNavBar={true} />
					</Route>
					<Route path='/temp-password-confirmation'>
						<TempPassword isNormalNavBar={true} />
					</Route>
					<Route path='/confirmation'>
						<ConfirmationCode isNormalNavBar={true} />
					</Route>
					<Route path='/AI'>
						<div onScroll={onScroll}>
							<AI isNormalNavBar={isNormalNavBar} />
						</div>
					</Route>
					<Route path='/register'>
						<Register isNormalNavBar={true} />
					</Route>
					<Route path='/'>
						<div className='App' onScroll={onScroll}>
							<Home isNormalNavBar={isNormalNavBar} goToRegisterHandler={goToRegisterHandler} ref={registerFormRef}/>
						</div>
					</Route>
				</Switch>
				<BottomAlert />
			</Router>
		</div>
	)
}

export default App

import { AIDiagnosisSuggestion, AIMedicalPlanSuggestion, AIPatienDiagnostic, AISection, AISuggestion, AIType, PatientData } from "../share/ai/types";

export interface IUSerContextProp {
    children: React.ReactNode;
}

export interface IUser {
    id: string;
    name: string;
    email: string;
    phone: string;
}

export type UserContextType = {
    user: IUser;
    isInvalidEmail: boolean;
    setUser: (user: IUser) => void;
    updateUserPassword: (password: string) => Promise<string>;
    handleChangeUser: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleChangeEmail: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    createPosibleClient: (phoneNumber: string) => void;
    createDoctor: (email: string, isTrial: boolean, useCreditCard: boolean, needVerification: boolean) => Promise<CreateDoctorResponseMessageType>;
    confirmSignUp: (username: string, confirmationCode: string) => void;
    setAlertMessage: (data: AlertType | null) => void;
	alertMessage: AlertType | null;
    resendConfirmationCode: (email: string) => Promise<void>;
    registerBroadcaster: (email:string, speciality: string) => Promise<string>;
    customTempPasswordAssigned: string;
}

export enum Tabs {
	Home = 0,
	AIDiagnostic = 1,
    Download = 2,
    GoToApp = 3,
}

export type NavigationContextType = {
    activeTab: Tabs;
    setActiveTab: (tab: Tabs) => void;
}

export interface AlertType {
	message: string | null;
	severity: 'error' | 'info' | 'success' | 'warning';
}


export interface AIContextProps {
    aiSuggestions: AISuggestion | AIMedicalPlanSuggestion | AIDiagnosisSuggestion | null;
    setIAPrompt: (prompt: AIPatienDiagnostic | null) => void;
    clearIASuggestions: () => void;
    setAiSection: (section: AISection | null) => void;
    aiSection: AISection | null;
    loading: boolean;
    setLoading: (loading: boolean) => void;
  }


export enum CreateDoctorResponseMessageType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
    INVALID_EMAIL = 'INVALID_EMAIL',
    VALIDATION_PENDING = 'VALIDATION_PENDING',
    NO_VALIDATION_NEEDED = 'NO_VALIDATION_NEEDED',
    PASSWORD_PENDING = 'PASSWORD_PENDING',
}
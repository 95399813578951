import {
	BackgroundContainer,
	DescriptionTypography,
	TrialInformationContainer,
	ImageContainer,
	InputButtonSection,
	PhoneInput,
	TitleTypography,
	TrialContainer,
	TrialImg,
} from './style'
import { FC, useContext, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { UserContextType } from '../../context/type'
import { TrialButton } from '../../share/style'
import phoneMask from '../masks/phoneMask'
import { TrialSectionProps } from './type'

const TrialSection: FC<TrialSectionProps> = ({ imageToDisplay }) => {
	const [userPhone, setUserPhone] = useState('')
	const { user, setUser, createPosibleClient } = useContext(
		UserContext
	) as UserContextType

	const handleChangePhone = (
		e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const target = e.target as HTMLInputElement
		setUserPhone(target.value)
	}

	const handleClickTry = (): void => {
		if (userPhone !== '') {
			setUser({ ...user, phone: userPhone })
			createPosibleClient(userPhone)
			document.getElementById('form-section')?.scrollIntoView()
			setUserPhone('')
		}
	}

	return (
		<BackgroundContainer container>
			<TrialContainer container>
				<TrialInformationContainer item xs={12} md={6}>
					<TitleTypography>
						Simplifica tu práctica médica desde ahora y para siempre
					</TitleTypography>
					<DescriptionTypography>
						Maneja las historias clínicas de tus pacientes, crea prescripciones
						y consultas, maneja tus listas de esperas y haz funcionar toda tu
						práctica con o sin internet.
					</DescriptionTypography>
					<InputButtonSection>
						<PhoneInput
							id='phone-number'
							margin='dense'
							name='phone'
							value={userPhone}
							onChange={handleChangePhone}
							placeholder='ej: 829-653-2245'
							variant='outlined'
							InputProps={{ inputComponent: phoneMask as any }}
						/>
						<TrialButton
							variant='contained'
							color='primary'
							size='small'
							onClick={handleClickTry}
							style={{
								height: '40px',
								minWidth: '163px',
							}}
						>
							probar gratis
						</TrialButton>
					</InputButtonSection>
				</TrialInformationContainer>
				<ImageContainer item xs={12} md={6}>
					<TrialImg src={imageToDisplay} alt='img-bg' width={'100%'} />
				</ImageContainer>
			</TrialContainer>
		</BackgroundContainer>
	)
}

export default TrialSection

import React from 'react'

const Flag = () => {
	return (
		<svg
			width='84'
			height='85'
			viewBox='0 0 84 85'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='m50.4 21.065-1.4-7H17.5v59.5h7v-24.5h19.6l1.4 7H70v-35H50.4z'
				fill='#D500F9'
			/>
		</svg>
	)
}

export default Flag

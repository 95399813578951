import { FC, useState, useEffect, forwardRef, useContext } from 'react'
import {
	NavigationContext,
} from '../context/NavigationContext'
import { NavigationContextType, Tabs } from '../context/type'

import BgHeaderMobile from '../assets/images/bgHeader_Mobile.png'
import bgHeader_744 from '../assets/images/bgHeader_744.png'
import bgHeader_1024 from '../assets/images/bgHeader_1024.png'
import bgHeader_1200 from '../assets/images/bgHeader_1200.png'
import bgHeader_1800 from '../assets/images/bgHeader_1800.png'
import Footer from '../components/footer/Footer'
import FormSection from '../components/formSection/FormSection'
import Header from '../components/header/Header'
import InformationSection from '../components/informationSection/InformationSection'
import TrialSection from '../components/trialSection/TrialSection'
import PriceSection from '../components/priceSection'
import useTolt from '../hooks/useTolt'


const userAgent = navigator.userAgent.toLowerCase()
export const isTabletOrMobile =
	/(iphone|android|ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
		userAgent
	)

interface Props {
	isNormalNavBar: Boolean
	goToRegisterHandler: () => void
	ref: React.RefObject<HTMLDivElement>
}

export function getWindowSize() {
	const { innerWidth } = window
	return innerWidth
}

const Home = forwardRef<HTMLDivElement, Props>(({ isNormalNavBar, goToRegisterHandler }, ref) => {
	const [windowSize, setWindowSize] = useState<number>(getWindowSize())
	const {
		setActiveTab,
	  } = useContext(NavigationContext) as NavigationContextType;
	let imageToDisplay = bgHeader_1800
	let isUnderWidth = false
	useTolt("ref")

	if (windowSize > 0 && windowSize <= 660) {
		imageToDisplay = BgHeaderMobile
		isUnderWidth = true
	}
	if (windowSize > 661 && windowSize <= 744) {
		imageToDisplay = bgHeader_744
		isUnderWidth = true
	}
	if (windowSize > 744 && windowSize <= 1074) {
		imageToDisplay = bgHeader_1024
		isUnderWidth = false
	}
	if (windowSize > 1075 && windowSize <= 1201) {
		imageToDisplay = bgHeader_1200
		isUnderWidth = false
	}
	if (windowSize > 1201 && windowSize <= 2000) {
		imageToDisplay = bgHeader_1800
		isUnderWidth = false
	}

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize())
		}

		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	useEffect(() => {
		setActiveTab(Tabs.Home)
	}, [])

	return (
		<>
			<Header
				isNormalNavBar={
					isTabletOrMobile || isUnderWidth ? false : isNormalNavBar
				}
			/>
			<TrialSection imageToDisplay={imageToDisplay} />
			<InformationSection />
			<PriceSection goToRegisterHandler={goToRegisterHandler} />
			<FormSection ref={ref}/>
			<Footer />
		</>
	);
});

export default Home

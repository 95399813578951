import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import UserProvider from './context/UserContext'
import { default as Amplify } from '@aws-amplify/core'
import { default as awsConfig } from './aws-exports'
import NavigationProvider from './context/NavigationContext'
import AiProvider from './context/ai-context'

Amplify.configure(awsConfig)


ReactDOM.render(
	<React.StrictMode>
		<NavigationProvider>
			<AiProvider>
				<UserProvider>
					<App />
				</UserProvider>
			</AiProvider>
		</NavigationProvider>
	</React.StrictMode>, 
	document.getElementById('root')
)

import { createTheme, Grid, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import styled from 'styled-components'

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 745,
			md: 1075,
			lg: 1201,
			xl: 2500,
		},
	},
})
export const BackgroundContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 660px)': {
			height: '850px',
		},
		'@media (min-width: 660px) and (max-width: 1024.95px)': {
			padding: theme.spacing(0),
			height: '777px',
		},
		[theme.breakpoints.only('sm')]: {
			height: '745px',
		},
		[theme.breakpoints.only('md')]: {
			height: '745px',
		},
		[theme.breakpoints.only('lg')]: {
			height: '777px',
		},
		height: '777px',
		backgroundColor: 'rgba(25, 118, 210, 0.08)',
		marginTop: '-77px',
	},
})(Grid) as typeof Grid
export const TrialContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 660px)': {
			padding: theme.spacing(0, 2, 0, 2),
			display: 'flex',
			flexWrap: 'nowrap',
		},
		'@media (min-width: 660px) and (max-width: 1024.95px)': {
			padding: theme.spacing(0),
			display: 'flex',
			flexWrap: 'nowrap',
			height: '100%',
			maxWidth: '684px',
			marginLeft: '30px',
			marginRight: '30px',
		},
		[theme.breakpoints.only('sm')]: {
			padding: theme.spacing(0),
			display: 'flex',
			flexWrap: 'nowrap',
			maxWidth: '1006px',
			marginLeft: '34px',
			marginRight: '34px',
		},
		[theme.breakpoints.only('md')]: {
			padding: theme.spacing(0),
			marginRight: 'auto',
			marginLeft: 'auto',
			maxWidth: '1024px',
			flexWrap: 'nowrap',
		},
		[theme.breakpoints.only('lg')]: {
			padding: theme.spacing(0),
			marginRight: 'auto',
			marginLeft: 'auto',
			maxWidth: '1120px',
			flexWrap: 'nowrap',
		},
		alignItems: 'center',
		flexDirection: 'column',
		height: '819px',
		display: 'flex',
	},
})(Grid) as typeof Grid
export const TrialInformationContainer = withStyles({
	root: {
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			maxWidth: '768px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '768px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
		},
		[theme.breakpoints.only('md')]: {
			maxWidth: '768px',
			display: 'flex',
			flexDirection: 'column',
		},
		[theme.breakpoints.only('lg')]: {
			maxWidth: '768px',
		},
		textAlign: 'center',
	},
})(Grid) as typeof Grid
export const ImageContainer = withStyles({
	root: {
		display: 'flex',
		'@media (min-width: 0px) and (max-width: 660.95px)': {
			maxWidth: '100%',
			flexBasis: '100%',
			marginTop: theme.spacing(4),
		},
		'@media (min-width: 660.95px) and (max-width: 744px)': {
			maxWidth: '100%',
			flexBasis: '100%',
			marginRight: '-8.5%',
		},
		'@media (min-width: 744px) and (max-width: 1073.95px)': {
			maxWidth: '100%',
			flexBasis: '100%',
			justifyContent: 'center',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '100%',
			flexBasis: '100%',
			justifyContent: 'center',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '100%',
			flexBasis: '100%',
			justifyContent: 'center',
		},
		[theme.breakpoints.only('md')]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		[theme.breakpoints.only('lg')]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		alignItems: 'center',
		justifyContent: 'flex-end',
		color: '#1565c0',
	},
})(Grid) as typeof Grid

export const TitleTypography = withStyles({
	root: {
		marginTop: theme.spacing(18),
		'@media (min-width: 0px) and (max-width: 660px)': {
			padding: 0,
			fontSize: '32px',
			textAlign: 'initial',
			marginBottom: theme.spacing(2),
		},
		'@media (min-width: 660px) and (max-width: 1024.95px)': {
			fontSize: '32px',
			maxWidth: '468px',
		},
		[theme.breakpoints.only('sm')]: {
			fontSize: '40px',
			maxWidth: '768px',
		},
		[theme.breakpoints.only('md')]: {
			fontSize: '48px',
			maxWidth: '768px',
		},
		[theme.breakpoints.only('lg')]: {
			fontSize: '48px',
			maxWidth: '768px',
		},
		padding: theme.spacing(0, 4),
		lineHeight: 1.2,
		fontFamily: 'NotoSans',
		fontWeight: 400,
		marginBottom: theme.spacing(3.5),
	},
})(Typography) as typeof Typography

export const DescriptionTypography = withStyles({
	root: {
		fontSize: '16px',
		lineHeight: 1.5,
		fontFamily: 'Roboto',
		fontWeight: 400,
		marginRight: 'auto',
		marginLeft: 'auto',
		maxWidth: '590px',
		'@media (min-width: 0px) and (max-width: 660px)': {
			padding: 0,
			textAlign: 'initial',
			margin: 0,
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			padding: 0,
		},
	},
})(Typography) as typeof Typography

export const InputButtonSection = styled.section`
	display: flex;
	@media (max-width: 660px) {
		flex-direction: column;
		width: 100%;
	}
	margin-bottom: ${theme.spacing(5)}px;
	justify-content: center;
	margin-top: ${theme.spacing(3.5)}px;
`

export const TrialImg = styled.img`
	@media (min-width: 0px) and (max-width: 660px) {
		left: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		max-width: 100vw;
		position: relative;
		right: 50%;
		width: 100vw;
	}
	@media (min-width: 660px) and (max-width: 1024.95px) {
	}
	@media (min-width: 1024.95px) and (max-width: 1200.95px) {
	}
	@media (min-width: 1200.95px) and (max-width: 1919.95px) {
	}
`
export const PhoneInput = withStyles({
	root: {
		marginTop: theme.spacing(0.5),
		marginRight: theme.spacing(2),
		'@media (min-width: 0px) and (max-width: 660px)': {
			marginRight: 0,
			marginBottom: theme.spacing(2),
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#78909C',
		},
		'@media (min-width: 0px) and (max-width: 550px)': {
			marginRight: '0px',
		},
		'& input': {
			backgroundColor: '#FFFFFF',
		},
		'& input::placeholder': {
			color: 'rgba(0, 0, 0, 0.6)',
			opacity: 1,
		},
	},
})(TextField) as typeof TextField

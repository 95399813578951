import { FC } from 'react'
import { Backdrop } from '@material-ui/core'
import './Modal.style.css'

interface Props {
	open: boolean
	handleClose: () => void
	children: React.ReactNode
}
const Modal: FC<Props> = ({
	open,
	handleClose,
	children,
}) => {
	return (
		<Backdrop
			className='backdrop'
			open={open}
			onClick={handleClose}
		>
			<div className='modal-body'>{children}</div>
		</Backdrop>
	)
}

export default Modal;

import { createTheme, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import styled from 'styled-components'

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 745,
			md: 1075,
			lg: 1201,
			xl: 2500,
		},
	},
})
export const InformationrContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 660px)': {
			padding: theme.spacing(6, 3, 0, 3),
			display: 'flex',
			flexDirection: 'column',
			marginTop: '58%',
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			display: 'flex',
			flexDirection: 'column',
			maxWidth: '662px',
			marginLeft: '30px',
			marginRight: '30px',
			marginTop: theme.spacing(42),
		},
		'@media (min-width: 743.95px) and (max-width: 1024.95px)': {
			display: 'flex',
			flexDirection: 'column',
			maxWidth: '662px',
			marginLeft: '34px',
			marginRight: '34px',
			marginTop: theme.spacing(42),
		},
		[theme.breakpoints.only('sm')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			maxWidth: '1006px',
			marginLeft: '34px',
			marginRight: '34px',
			marginTop: '33%',
		},
		[theme.breakpoints.only('md')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row',
			marginRight: 'auto',
			marginLeft: 'auto',
			maxWidth: '1024px',
			marginTop: theme.spacing(55),
		},
		[theme.breakpoints.only('lg')]: {
			display: 'flex',
			flexDirection: 'row',
			maxWidth: '1120px',
			marginLeft: 'auto',
			marginRight: 'auto',
			marginTop: theme.spacing(55),
		},
		display: 'flex',
		marginTop: theme.spacing(55),
	},
})(Grid) as typeof Grid

export const InformationContainer = withStyles({
	root: {
		[theme.breakpoints.only('xs')]: {
			maxWidth: '100%',
			flexBasis: '100%',
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			maxWidth: '50%',
			flexBasis: '50%',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '50%',
			flexBasis: '50%',
		},
		display: 'flex',
		flexDirection: 'column',
	},
})(Grid) as typeof Grid

export const ColumnContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 660px)': {
			padding: theme.spacing(3, 0, 0, 0),
			display: 'flex',
			marginLeft: 0,
			maxWidth: '100%',
			flexBasis: '100%',
			flexDirection: 'column',
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			padding: theme.spacing(3, 0, 0, 0),
			display: 'flex',
			marginLeft: 0,
			maxWidth: '100%',
			flexBasis: '100%',
			flexDirection: 'row',
		},
		'@media (min-width: 743.95px) and (max-width: 1074.95px)': {
			padding: theme.spacing(3, 0, 0, 0),
			display: 'flex',
			marginLeft: 0,
			maxWidth: '100%',
			flexBasis: '100%',
			flexDirection: 'row',
		},
		[theme.breakpoints.only('sm')]: {
			padding: theme.spacing(3, 0, 0, 0),
			display: 'flex',
			marginLeft: 0,
			maxWidth: '100%',
			flexBasis: '100%',
			flexDirection: 'row',
		},
		[theme.breakpoints.only('md')]: {
			maxWidth: '68%',
			flexBasis: '68%',
			marginLeft: '4%',
		},
		[theme.breakpoints.only('lg')]: {
			maxWidth: '65%',
			flexBasis: '65%',
			padding: theme.spacing(3, 0, 0, 0),
		},
		display: 'flex',
		flexGrow: 0,
		maxWidth: '60%',
		flexBasis: '60%',
	},
})(Grid) as typeof Grid

export const VideoTitleContainer = withStyles({
	root: {
		'@media (min-width: 0px) and (max-width: 660px)': {
			maxWidth: '472px',
			flexBasis: '60%',
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			maxWidth: '472px',
			flexBasis: '60%',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '472px',
			flexBasis: '60%',
		},
		[theme.breakpoints.only('md')]: {
			maxWidth: '28%',
			flexBasis: '28%',
		},
		[theme.breakpoints.only('lg')]: {
			maxWidth: '25%',
			flexBasis: '25%',
			marginRight: '10%',
			padding: theme.spacing(3, 0, 0, 0),
		},
	},
})(Grid) as typeof Grid

export const VideoTitleTypography = withStyles({
	root: {
		fontSize: '32px',
		'@media (min-width: 0px) and (max-width: 660px)': {
			maxWidth: '400px',
			fontSize: '26px',
		},
		'@media (min-width: 660px) and (max-width: 743.95px)': {
			maxWidth: '400px',
			fontSize: '26px',
		},
		[theme.breakpoints.only('sm')]: {
			maxWidth: '500px',
			fontSize: '32px',
		},
		lineHeight: 1.4,
		fontFamily: 'NotoSans',
		fontWeight: 400,
		marginBottom: theme.spacing(2.5),
	},
})(Typography) as typeof Typography

export const ColInformationContainer = withStyles({
	root: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(4),
		},
		marginBottom: theme.spacing(8),
		flexGrow: 0,
		maxWidth: '85%',
		flexBasis: '85%',
	},
})(Grid) as typeof Grid

const LineColor = styled.div`
	margin-top: 10px;
	width: 49.2px;
	height: 5px;
	border-radius: 38px;
	margin-bottom: 10px;
`
export const LineYellowColor = styled(LineColor)`
	background-color: #ff9800;
`
export const LineGreenColor = styled(LineColor)`
	background-color: #1b5e20;
`
export const LineBlueColor = styled(LineColor)`
	background-color: #1565c0;
`
export const LineGrayColor = styled(LineColor)`
	background-color: #9e9e9e;
`
export const ListContainer = styled.ul`
	padding-left: 22px;
	font-family: Roboto;
	font-size: 16px;
	color: #424242;
	line-height: 1.5;
`

export const InformationTypography = withStyles({
	root: {
		fontSize: '22px',
		lineHeight: 1.4,
		fontFamily: 'Roboto',
		fontWeight: 400,
		marginBottom: theme.spacing(2),
	},
})(Typography) as typeof Typography

import React, { FC, useRef } from 'react';
import {
  AIReportContainer,
  AIReportDescription,
  AIReportTitle,
  AISection,
  AiReportDescriptionList,
  PrintButton,
  PrintContainer,
} from './styles';
import ReactToPrint from 'react-to-print';
import AIReportDescriptionListElements from './AIReportDescriptionListElements';
import PrintAIIcon from './PrintAIIcon';

import './AIDiagnosticReport.style.css';

interface props {
  description: string;
  study: string;
  treatment: string;
  medicines: string;
  diagnostics: string;
}

const AIReport: FC<props> = ({
  description,
  study,
  treatment,
  medicines,
  diagnostics,
}) => {
  const claimRef = useRef(null);

  return (
    <AIReportContainer ref={claimRef} className='AI_report__diagnostics'>
      <AISection>
        <AIReportTitle>Plan Médico del paciente</AIReportTitle>
        <ReactToPrint
          trigger={() => {
            return (
              <PrintContainer className="print-hide">
                <PrintAIIcon />
                <PrintButton >imprimir</PrintButton>
              </PrintContainer>
            );
          }}
          content={() => claimRef.current}
        />
      </AISection>
      <AIReportDescription className='mt-1'>{description}</AIReportDescription>
      <AIReportTitle>Diagnósticos presuntivos</AIReportTitle>
      <AIReportDescription >
        <AiReportDescriptionList>
          <AIReportDescriptionListElements data={diagnostics} />
        </AiReportDescriptionList>
      </AIReportDescription>
      <AIReportTitle>Estudios</AIReportTitle>
      <AIReportDescription>
        <AiReportDescriptionList>
          <AIReportDescriptionListElements data={study} />
        </AiReportDescriptionList>
      </AIReportDescription>
      <AIReportTitle>Posibles medicamentos</AIReportTitle>
      <AIReportDescription>
        <AiReportDescriptionList>
          <AIReportDescriptionListElements data={medicines} />
        </AiReportDescriptionList>
      </AIReportDescription>
      <AIReportTitle>Tratamiento médico</AIReportTitle>
      <AIReportDescription>
        <AiReportDescriptionList>
          <AIReportDescriptionListElements data={treatment} />
        </AiReportDescriptionList>
      </AIReportDescription>
    </AIReportContainer>
  );
};

export default AIReport;

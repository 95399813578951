import React, { FC } from 'react';
import { AIReportDescription, AIReportTitle } from './styles';
import { IADiagnostic } from '../../share/ai/types';

import './AIDiagnosticReport.style.css';

interface Props {
  diagnostic: IADiagnostic;
  index: number;
}


export const DiagnosticSection: FC<Props> = ({
  diagnostic,
  index,
}) => {
  return (
    <div
      key={`${diagnostic.nombre}-${index}`}
      className='AI_report__diagnostics'
    >
      <AIReportTitle>
        {index + 1}- {diagnostic.nombre}
      </AIReportTitle>
      <AIReportDescription
        style={{
          marginBottom: '20px',
        }}
      >
        {diagnostic.descripcion}
      </AIReportDescription>
    </div>
  );
};

export default DiagnosticSection;

const generateURIBody = (data: any) => {
	/* 
		here will be created a new array from the JSON received in data 
		then will be created a new array using map to create the URI body as is requested,
		Ex: 
		{
		customer: cus_123123,
		subscription: sub_12333
		}
		will be converted to
		customer=cus_123123&subscription=sub_12333
	*/
	const formBody = Object.entries(data).map(([key, value]) => {
		if (typeof value === 'object') {
			return generateURIProperty(key, value);
		}
		return `${key}=${value}`;
	});
	return formBody.join('&');
};

const generateURIProperty = (parentKey: string, object: any) => {
	const objectURI: any = Object.entries(object).map(([key, value]) => {
		if (typeof value === 'object') {
			return generateURIProperty(`${parentKey}[${key}]`, value);
		}
		return `${parentKey}[${key}]=${value}`;
	});
	return objectURI.join('&');
};

export const createCustomer = async (customerData: any) => {
	const customerCreated = await fetch('https://api.stripe.com/v1/customers', {
		method: 'POST',
		body: generateURIBody(customerData),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(customerCreated);
};

export const createStripeSubscription = async (subscriptionData: any) => {
	const subscriptionCreated = await fetch('https://api.stripe.com/v1/subscriptions', {
		method: 'POST',
		body: generateURIBody(subscriptionData),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(subscriptionCreated);
};

const getResponse = async (value: any) => {
	const resp = await value.json();

	if (Object.prototype.hasOwnProperty.call(resp, 'error')) {
		throw new Error(resp.error.message);
	}

	return resp;
};
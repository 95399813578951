import {
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    TextField,
    Button,
    Chip,
    createTheme,
  } from '@material-ui/core';
import styled from 'styled-components';


  const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1100,
			xl: 1536,
		},
	},
});

export const AIContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgba(25, 118, 210, 0.08) 50%, #FFFFFF 50%);
  transition: all .5s ease;
  WebkitTransition: all .5s ease;
  MozTransition: all .5s ease;
`;

export const ReportContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0px;
`
  
  export const CustomTypography = styled(Typography)<{
    subTitle?: boolean;
  }>`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 32px;
    line-height: 133.4%;
    /* identical to box height, or 32px */
  
    text-align: center;
  
    color: #000000;
    margin-top: 2rem;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      text-align: left;
      
      ${(props) =>
        props.subTitle && `
        margin-bottom: 20px !important;
        `}
      }
    }
  `;

  export const CustomParagraph = styled.p`
    /* Typography/Body 2 */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    /* identical to box height, or 20px */
    
    letter-spacing: 0.17px;
    
    /* Light/Text/Primary */
    
    color: rgba(0, 0, 0, 0.87);
    margin: 0px;
  `;
  
  export const SuggestionContainer = styled(Grid)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      flex-direction: column;
      row-gap: 10px;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
     }
  `;
  
  export const AICardContainer = styled(Card)`
    background: #ffffff;
    max-width: 740px;
    width: 100%;
    min-height: 230px;
    margin: 20px auto;
    padding: 0px 24px 10px 24px;

    @media (max-width: ${theme.breakpoints.values.md}px) {
      width: 85%;
      min-height: 310px;
    }
  `;
  
  export const AICardHeader = styled(CardHeader)`

  .MuiCardHeader-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */
  
    letter-spacing: 0.15px;
  
    color: #000000;
  }
    &.MuiCardHeader-root {
      padding: 16px 16px 0px 16px;
    }
  `;
  
  export const AICardContent = styled(CardContent)`
    height: 95px;
  `;
  
  export const AICardActions = styled(CardActions)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px 16px 16px 16px;

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      justify-content: center;
      flex-direction: column;
      row-gap: 10px;
      align-items: center;
    }
  `;
  
  export const AITextField = styled(TextField)`
    width: 100%;
    max-height: 76px;
  `;
  
  export const AIButton = styled(Button)<{
    customDisabled?: boolean;
  }>`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
  
    letter-spacing: 0.4px;
    text-transform: uppercase;
    border-radius: 4px;
    ${(props) =>
      props.customDisabled &&
      `
        background: transparent !important;
        color: #9C27B0 !important;
        border: 1px solid rgba(156, 39, 176, 0.5) !important;
        border-radius: 4px !important;
        :disabled {
            color: rgba(0, 0, 0, 0.26) !important;
            flex: none;
            order: 0;
            flex-grow: 0;
            border: 1px solid rgba(0, 0, 0, 0.12);
        }
      `
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      margin-left: 0px !important;
      width: 100% !important;
    }
  `;
  
  export const AIReportContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    max-width: 740px;
  `;
  
  export const AIReportTitle = styled.h5`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 133.4%;
    /* identical to box height, or 32px */
    
    margin: 20px 0px;
    color: #000000;
  `;
  
  export const AIReportDescription = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  
    letter-spacing: 0.15px;
  
    color: #000000;
    margin: 0px;
  `;
  
  export const PrintContainer = styled.div`
    display: flex;
    column-gap: 9.5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `;
  
  export const AISection = styled.div`
    display: flex;
    column-gap: 20.5px;
    align-items: baseline;
  `;
  
  export const PrintButton = styled.h4`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height, or 169% */
  
    letter-spacing: 0.46px;
    text-transform: uppercase;
  
    /* Light/Primary/Main */
  
    color: #1976d2;
  `;
  
  export const AiReportDescriptionList = styled.ul`
    list-style-type: none;
    margin: 0px;
  `;
  
  export const StyledChip = styled(Chip)<{
    removeDisabledStyle?: boolean;
    customHoverEffect?: boolean;
  }>`
      font-family: 'Roboto';
      font-style: 'normal';
      font-weight: 500;
      font-size: '14px';
      line-height: '24px';
      letter-spacing: '0.17px';
      color: 'rgba(0, 0, 0, 0.87)';
      border: '1px solid rgba(0, 0, 0, 0.26)';
      border-radius: '16px';
      margin-bottom: '0px';
      margin-right: '10px';
    ${
      props=> props.removeDisabledStyle && `
      &.MuiChip-root.Mui-disabled {
        opacity: 1;
      }`
    }

    &:hover, &:focus {
        background-color: #DFDECE !important;
        border-color: transparent !important;
        border: 1px solid !important;
    }
      
    
  ` as typeof Chip;
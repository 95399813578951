import { OS } from "./types";

export const MONTHLY_PLAN_ID = 2;
export const ANNUALLY_PLAN_ID = 1;

export const MOBILE_SCREEN_WIDTH = 768;

export const infColumn1 = [
	{
		id: 1,
		color: 'yellow',
		title: 'Maneja los historiales de tus pacientes',
		body: [
			{ id: 1, text: 'Crea consultas' },
			{ id: 2, text: 'Añade alergias' },
			{ id: 3, text: 'Agrega enfermedades activas' },
			{ id: 4, text: 'Agrega notas importantes' },
			{ id: 5, text: 'Guarda imágenes de tus pacientes' },
		],
	},
	{
		id: 2,
		color: 'gray',
		title: 'Maneja tu consultorio',
		body: [
			{ id: 1, text: 'Crea secretarias o administradores' },
			{ id: 2, text: 'Maneja tus listas de medicamentos' },
			{ id: 3, text: 'Crea facturas de tus servicios (Muy pronto)' },
			{ id: 4, text: 'Estadísticas de Consultorio (Muy pronto)' },
			{ id: 5, text: 'Marketing digital (Muy pronto)'}
		],
	},
]

export const infColumn2 = [
	{
		id: 1,
		color: 'blue',
		title: 'Crea documentos de impresión y de aseguradoras',
		body: [
			{ id: 1, text: 'Crea prescripciones' },
			{ id: 2, text: 'Imprime reclamaciones de aseguradoras' },
			{ id: 3, text: 'Imprime tus historiales médicos' },
			{ id: 4, text: 'Crea formularios (Muy Pronto)' },
			{ id: 5, text: 'Imprime informes de pacientes (Muy pronto)' },
		],
	},
	{
		id: 2,
		color: 'green',
		title: 'Maneja a tus pacientes',
		body: [
			{ id: 1, text: 'Crea pacientes' },
			{ id: 2, text: 'Organiza tu lista de espera' },
			{ id: 3, text: 'Asigna seguros para cada paciente' },
			{ id: 4, text: 'Agenda citas y tele llamadas (Muy pronto)' },
		],
	},
]

export const tempPassword = 'Temp123!';

export const OLD_URL_VIDEO = "https://youtu.be/7g_YJIpaZ_E";
export const URL_VIDEO = "https://www.youtube.com/watch?v=fcl72SnGjOs"

export const retryInterval = 500;

export const TERM_LINK = "https://devstorage32927-produccion.s3.amazonaws.com/public/downloads/CONTRATO+DE+LICENCIA+DE+USUARIO+FINAL+.pdf";
export const CONDITIONS_LINK = "https://devstorage32927-produccion.s3.amazonaws.com/public/downloads/ACUERDO+DE+SUSCRIPCION+DEL+USUARIO-FINAL.pdf";

export const WINDOWS_DOWNLOAD_URL = "https://devstorage32927-produccion.s3.amazonaws.com/public/downloads/Pulseara-Setup.exe";
export const MAC_DOWNLOAD_URL = "https://devstorage32927-produccion.s3.amazonaws.com/public/downloads/Pulseara.dmg";
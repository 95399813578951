import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { OS } from './types';
import moment from 'moment';
import { WINDOWS_DOWNLOAD_URL, MAC_DOWNLOAD_URL } from './constant';
import AppleMacIcon from '../components/svg/AppleMacIcon';
import WindowIcon from '../components/svg/WindowIcon';
import crypto from 'crypto-js';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const detectOS = (): OS => {
  const { platform, userAgent } = navigator;

  if (platform.startsWith('Mac')) {
    if (userAgent.includes('Macintosh') && userAgent.includes('Intel')) {
      return OS.Mac;
    } else if (
      userAgent.includes('Macintosh') &&
      userAgent.includes('Apple Silicon')
    ) {
      return OS.MacM1;
    }
  }

  if (platform.startsWith('Win')) {
    return OS.Windows;
  }

  return OS.Unknown;
};

export const getDownloadFileURLByOS = (operationSystem: OS): string => {
  if (operationSystem === OS.Windows) {
    return WINDOWS_DOWNLOAD_URL;
  } else {
    return MAC_DOWNLOAD_URL;
  }
};

export const getDownloadIconByOS = (operationSystem: OS) => {
  if (operationSystem === OS.Windows) {
    return WindowIcon;
  } else {
    return AppleMacIcon;
  }
};

export const getTrialEndDate = () => {
  const currentDate = moment.utc();
  const trialEndDate = currentDate.clone().add(1, 'year');
  return trialEndDate;
};

export function generatePassword() {
  const length = 8;
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
  let password = '';

  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * characters.length);
    password += characters.charAt(index);
  }

  return password;
}
export function encryptMessage(data: object | string) {
  const key = process.env.REACT_APP_KEY_MESSAGE as string;
  const encryptedMessage = crypto.AES.encrypt(JSON.stringify(data), key);
  const urlSafeEncryptedMessage = encodeURIComponent(
    encryptedMessage.toString()
  );
  return urlSafeEncryptedMessage;
}

import React from 'react';

const BrandName = () => {
    return (
        <svg width="78" height="19" viewBox="0 0 78 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.26958 14.4058C2.86386 14.2801 2.47467 14.1057 2.1104 13.8865V11.5811C2.4079 11.8326 2.73864 12.0415 3.09318 12.2018C3.51941 12.3986 3.98509 12.494 4.45396 12.4804C4.83482 12.4977 5.21497 12.4332 5.5691 12.2913C5.92323 12.1493 6.24321 11.9331 6.50773 11.6571C7.06944 10.9392 7.33958 10.0339 7.26372 9.12368C7.29445 8.47139 7.17367 7.82086 6.91092 7.22364C6.696 6.78081 6.3477 6.41736 5.91554 6.18495C5.48593 5.95016 5.0059 5.82408 4.51696 5.8176C4.15904 5.80713 3.80175 5.85416 3.45858 5.95694C3.18576 6.03419 2.92341 6.14479 2.67739 6.28628V13.1644H0.00622643V6.08361C-0.000842629 5.79196 0.0686976 5.5036 0.207828 5.24759C0.37093 4.99647 0.59121 4.78804 0.850416 4.63958C1.34523 4.36872 1.86949 4.1562 2.41279 4.00623C3.0967 3.80964 3.80574 3.71573 4.51696 3.72755C5.48213 3.71236 6.43962 3.90229 7.32671 4.2849C8.14211 4.64506 8.82189 5.25746 9.26709 6.03294C9.77065 6.98209 10.0142 8.04877 9.97268 9.12368C10.003 10.1729 9.77357 11.2132 9.30488 12.1511C8.89345 12.9298 8.26221 13.5688 7.49051 13.9878C6.67774 14.4437 5.76267 14.6835 4.83195 14.6845C4.29991 14.6738 3.77278 14.5798 3.26958 14.4058ZM2.67739 18.2059C2.5144 18.2662 2.34543 18.3086 2.17339 18.3326C1.93863 18.3528 1.70257 18.3528 1.46781 18.3326C1.08182 18.3641 0.69632 18.2661 0.371622 18.0539C0.239364 17.9284 0.137612 17.7741 0.0742472 17.6027C0.0108827 17.4314 -0.0123935 17.2477 0.00622643 17.0659V12.4551H2.67739V18.2059Z" fill="black"/>
            <path d="M14.5845 9.7204H11.9134V4.05827L12.4174 3.95693C12.6563 3.93623 12.8966 3.93623 13.1356 3.95693C13.5199 3.9254 13.9041 4.01864 14.2317 4.22294C14.3623 4.34646 14.4622 4.49905 14.5235 4.66844C14.5848 4.83783 14.6057 5.01931 14.5845 5.19829V9.7204ZM11.9134 10.3917V8.66904H14.5845V10.3411C14.5526 10.6446 14.5892 10.9516 14.6918 11.2389C14.7943 11.5262 14.9601 11.7866 15.1767 12.0004C15.6521 12.3549 16.2365 12.5298 16.8273 12.4945C17.2218 12.5047 17.6158 12.4621 17.9991 12.3678C18.238 12.325 18.4708 12.2526 18.6921 12.1525V8.73237H21.3506V12.3678C21.361 12.6554 21.305 12.9416 21.1868 13.2038C21.0456 13.4668 20.831 13.6825 20.5694 13.8245C20.0731 14.1079 19.5383 14.317 18.9819 14.4452C18.2644 14.6209 17.5279 14.706 16.7895 14.6985C15.9003 14.7149 15.0158 14.5646 14.1813 14.2552C13.4805 13.9983 12.8858 13.512 12.493 12.8745C12.0578 12.1235 11.856 11.2589 11.9134 10.3917ZM21.3758 9.7204H18.6921V4.05827L19.1961 3.95693C19.4309 3.93719 19.6669 3.93719 19.9017 3.95693C20.2898 3.92707 20.6775 4.02009 21.0104 4.22294C21.1387 4.34767 21.2363 4.5008 21.2954 4.67013C21.3544 4.83946 21.3734 5.02031 21.3506 5.19829L21.3758 9.7204Z" fill="black"/>
            <path d="M26.6295 10.0881L23.9709 9.96144V0.182544L24.4623 0.0812088C24.694 0.0295536 24.9306 0.00406128 25.1679 0.00520648C25.5617 -0.0244563 25.9546 0.0731853 26.2893 0.28388C26.415 0.407831 26.511 0.558944 26.5699 0.725749C26.6289 0.892554 26.6492 1.07067 26.6295 1.24657V10.0881ZM23.9709 8.58074L26.6295 8.72008V14.2809C26.4709 14.3414 26.3061 14.3839 26.1381 14.4075C25.8991 14.4282 25.6588 14.4282 25.4199 14.4075C25.0359 14.4356 24.6527 14.3426 24.3237 14.1415C24.1997 14.0132 24.1042 13.86 24.0434 13.6918C23.9826 13.5237 23.9579 13.3446 23.9709 13.1662V8.58074Z" fill="black"/>
            <path d="M37.138 11.4194C37.1573 11.8793 37.064 12.337 36.8663 12.7522C36.6686 13.1674 36.3725 13.5275 36.004 13.8008C35.0377 14.4356 33.8925 14.7379 32.7407 14.6622C31.7681 14.6991 30.7996 14.5174 29.9057 14.1301C29.1623 13.7881 28.7969 13.3195 28.7969 12.7368C28.7985 12.4976 28.8591 12.2626 28.9733 12.0528C29.0793 11.8547 29.2359 11.6885 29.4269 11.5714C29.8726 11.8511 30.3454 12.0846 30.8381 12.2681C31.4192 12.4805 32.0343 12.5835 32.6525 12.5721C33.9125 12.5721 34.4921 12.1921 34.4921 11.4321C34.4956 11.2872 34.4681 11.1433 34.4116 11.01C34.3551 10.8767 34.2708 10.7572 34.1645 10.6594C33.8747 10.435 33.5315 10.2908 33.1691 10.2414L31.9091 9.91205C31.0442 9.76905 30.2341 9.39253 29.5655 8.82269C29.2984 8.57105 29.089 8.26392 28.9519 7.92266C28.8148 7.5814 28.7533 7.21421 28.7717 6.84664C28.7658 6.3973 28.8694 5.95334 29.0737 5.55364C29.278 5.15393 29.5767 4.81067 29.9435 4.55392C30.8593 3.91992 31.9579 3.60818 33.0683 3.66724C33.6922 3.66112 34.3144 3.73346 34.9204 3.88257C35.3789 3.98527 35.8092 4.18857 36.1804 4.47792C36.3202 4.59168 36.4341 4.73418 36.5145 4.89586C36.5949 5.05754 36.6399 5.23464 36.6466 5.41528C36.6464 5.64499 36.5901 5.87115 36.4828 6.07396C36.3847 6.26285 36.2364 6.4207 36.0544 6.52997C35.8265 6.40136 35.5909 6.28714 35.3488 6.18796C35.0243 6.05154 34.6865 5.94965 34.3409 5.88395C33.9723 5.80358 33.5966 5.76114 33.2195 5.75729C32.7583 5.73848 32.2996 5.83419 31.8839 6.03596C31.7403 6.10694 31.6193 6.21695 31.5347 6.35352C31.45 6.49008 31.4052 6.64775 31.4051 6.80864C31.4037 6.92799 31.4276 7.04626 31.4755 7.1555C31.5233 7.26475 31.5938 7.36243 31.6823 7.44199C31.9651 7.65144 32.2933 7.79036 32.6399 7.84733L33.7361 8.11334C34.6676 8.28182 35.5375 8.69725 36.256 9.3167C36.5417 9.58527 36.7679 9.91131 36.9198 10.2736C37.0718 10.6359 37.1461 11.0263 37.138 11.4194Z" fill="black"/>
            <path d="M40.3131 10.5434L40.2123 8.66873L46.0082 7.7947C45.9728 7.2578 45.7496 6.75078 45.3782 6.36334C45.1634 6.15137 44.9064 5.98759 44.6241 5.88279C44.3417 5.77798 44.0405 5.73454 43.7402 5.75533C43.3964 5.74367 43.0541 5.80665 42.7366 5.93999C42.4192 6.07333 42.1341 6.27389 41.9007 6.52801C41.3778 7.13934 41.1034 7.92634 41.1321 8.73206L41.2077 9.87209C41.235 10.2683 41.3444 10.6543 41.5288 11.0055C41.7133 11.3567 41.9687 11.6653 42.2787 11.9115C42.927 12.3674 43.7055 12.5986 44.4962 12.5702C45.5337 12.5655 46.548 12.2622 47.4194 11.6961C47.618 11.8174 47.7827 11.9874 47.8982 12.1901C48.0227 12.3775 48.0886 12.5981 48.0872 12.8235C48.0873 13.0148 48.0415 13.2033 47.9538 13.3731C47.8661 13.5429 47.739 13.6889 47.5832 13.7988C47.1857 14.0968 46.7309 14.3082 46.2476 14.4195C45.6159 14.5684 44.9687 14.6407 44.3198 14.6349C43.3031 14.6533 42.2954 14.4412 41.3715 14.0142C40.527 13.6136 39.8239 12.9642 39.3555 12.1521C38.8373 11.2077 38.5847 10.1392 38.6247 9.0614C38.6087 8.2645 38.75 7.47232 39.0405 6.73068C39.28 6.10932 39.6446 5.54439 40.1115 5.07131C40.5837 4.62875 41.1412 4.28818 41.7495 4.07062C42.3752 3.84143 43.0365 3.72563 43.7024 3.72861C44.5781 3.70382 45.4455 3.90439 46.2224 4.31129C46.9207 4.67671 47.505 5.22906 47.9108 5.90733C48.332 6.61904 48.5459 7.4354 48.5282 8.26338C48.5417 8.42714 48.5206 8.59193 48.4663 8.74693C48.4121 8.90193 48.3258 9.04363 48.2132 9.16274C47.9642 9.37237 47.6553 9.49663 47.3312 9.51741L40.3131 10.5434Z" fill="black"/>
            <path d="M54.2739 3.73004C55.4651 3.66938 56.6455 3.98376 57.6506 4.62939C58.0803 4.96126 58.4206 5.39582 58.6406 5.89357C58.8607 6.39132 58.9535 6.93647 58.9106 7.47946V12.5462C58.9249 12.8419 58.8357 13.1332 58.6586 13.3696C58.4763 13.586 58.259 13.7702 58.016 13.9143C57.5336 14.1561 57.0207 14.3308 56.4915 14.4336C55.8058 14.5932 55.1036 14.6698 54.3999 14.6616C53.2066 14.7311 52.0204 14.4352 50.9979 13.8129C50.5828 13.5415 50.2472 13.1636 50.0257 12.7183C49.8043 12.273 49.7049 11.7763 49.738 11.2795C49.7096 10.8618 49.7779 10.4431 49.9374 10.0563C50.097 9.66947 50.3436 9.32509 50.6577 9.05016C51.4335 8.47192 52.364 8.14097 53.3289 8.10014L56.2773 7.79613V7.40346C56.2959 7.15887 56.2511 6.91359 56.1474 6.69158C56.0436 6.46957 55.8843 6.27841 55.6851 6.13676C55.1551 5.87068 54.561 5.76088 53.9715 5.82009C53.4134 5.81852 52.8579 5.89526 52.3209 6.04809C51.8307 6.17444 51.3539 6.34837 50.8971 6.56744C50.7354 6.43573 50.6024 6.27186 50.5065 6.08609C50.3891 5.89609 50.3279 5.67641 50.3302 5.45274C50.3407 5.19436 50.4297 4.94542 50.5851 4.73936C50.7405 4.5333 50.9549 4.38 51.1995 4.30005C51.6772 4.07567 52.1878 3.93024 52.7115 3.86937C53.2279 3.7822 53.7503 3.73562 54.2739 3.73004ZM54.3873 12.5969C54.7816 12.6028 55.1751 12.5603 55.5591 12.4702C55.8235 12.4095 56.078 12.3114 56.3151 12.1789V9.64551L54.1731 9.86085C53.6942 9.86553 53.225 9.99657 52.8123 10.2409C52.6626 10.3422 52.5412 10.4804 52.4596 10.6423C52.378 10.8041 52.339 10.9842 52.3461 11.1655C52.3408 11.3613 52.384 11.5553 52.472 11.7301C52.56 11.9048 52.69 12.0547 52.8501 12.1662C53.3044 12.4706 53.8416 12.6256 54.3873 12.6096V12.5969Z" fill="black"/>
            <path d="M63.9752 6.49209V9.48149H61.304V6.27675C61.2955 5.94849 61.3879 5.62558 61.5686 5.35206C61.7705 5.07571 62.0176 4.83585 62.2994 4.64271C62.8194 4.35625 63.3742 4.13908 63.95 3.99669C64.6495 3.81486 65.3695 3.72544 66.092 3.73069C67.4528 3.73069 68.1331 4.1487 68.1331 4.99738C68.1304 5.18645 68.1007 5.37415 68.0449 5.55473C67.9862 5.70838 67.9146 5.8567 67.8307 5.99807L67.289 5.92207C67.0581 5.90911 66.8268 5.90911 66.596 5.92207C66.1042 5.92249 65.614 5.97771 65.1344 6.08674C64.7321 6.17075 64.3425 6.30701 63.9752 6.49209ZM61.304 8.58213L63.9752 8.72147V14.2823C63.8122 14.3426 63.6432 14.385 63.4712 14.4089C63.2364 14.4292 63.0004 14.4292 62.7656 14.4089C62.3816 14.437 61.9985 14.344 61.6694 14.1429C61.5374 14.02 61.4357 13.8679 61.3722 13.6986C61.3088 13.5293 61.2855 13.3475 61.304 13.1676V8.58213Z" fill="black"/>
            <path d="M73.2619 3.72967C74.4529 3.67069 75.6328 3.98494 76.6386 4.62902C77.0665 4.96242 77.4056 5.39725 77.6255 5.8946C77.8453 6.39196 77.9392 6.93635 77.8986 7.47909V12.5459C77.9165 12.84 77.8318 13.1311 77.6592 13.3692C77.4739 13.5827 77.2572 13.7664 77.0166 13.9139C76.5325 14.1765 76.0155 14.3725 75.4794 14.4966C74.7896 14.6565 74.0832 14.733 73.3753 14.7246C72.1861 14.7923 71.0045 14.4964 69.9859 13.8759C69.5689 13.6064 69.2318 13.2289 69.0101 12.7831C68.7883 12.3373 68.6902 11.8396 68.7259 11.3425C68.6975 10.9247 68.7658 10.506 68.9254 10.1192C69.085 9.73243 69.3315 9.38806 69.6457 9.11313C70.4196 8.53136 71.3512 8.20002 72.3169 8.1631L75.2652 7.8591V7.46642C75.2838 7.2205 75.2377 6.97401 75.1315 6.75168C75.0254 6.52934 74.8629 6.33899 74.6604 6.19972C74.1521 5.91982 73.576 5.78821 72.9973 5.81972C72.4433 5.81816 71.892 5.89492 71.3593 6.04772C70.8643 6.17167 70.3831 6.34567 69.9229 6.56707C69.7646 6.43194 69.6322 6.2688 69.5323 6.08572C69.4235 5.89267 69.367 5.67427 69.3685 5.45237C69.3781 5.19554 69.4653 4.94773 69.6183 4.74184C69.7713 4.53596 69.983 4.38174 70.2253 4.29968C70.6642 4.08968 71.131 3.94462 71.6113 3.869C72.1567 3.77654 72.7088 3.72993 73.2619 3.72967ZM73.3627 12.5965C73.757 12.6039 74.1507 12.5614 74.5344 12.4699C74.8004 12.4144 75.0557 12.316 75.2904 12.1785V9.64514L73.1485 9.86048C72.6733 9.86314 72.2076 9.99441 71.8003 10.2405C71.6488 10.3401 71.526 10.4779 71.4442 10.6402C71.3624 10.8025 71.3244 10.9835 71.3341 11.1652C71.3284 11.3596 71.3703 11.5525 71.4559 11.727C71.5416 11.9014 71.6685 12.0521 71.8255 12.1659C72.2805 12.4687 72.8171 12.6235 73.3627 12.6092V12.5965Z" fill="black"/>
        </svg>

    )
};

export default BrandName;

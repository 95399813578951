import React from 'react';

import './Banner.style.css';

const Banner = () => {

    return (
        <div className="banner">
            <p className="banner__content">
                Las características de esta página están diseñadas para ser utilizadas exclusivamente por profesionales de la salud. Su finalidad no es sustituir el asesoramiento médico.
            </p>
        </div>
    )
};

export default Banner;
import React, { useState } from 'react'
import { default as Amplify } from '@aws-amplify/core'
import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import { AlertType, CreateDoctorResponseMessageType, IUser, IUSerContextProp, UserContextType } from './type'
import { createCustomer, createStripeSubscription } from '../stripe/stripe'
import { ANNUALLY_PLAN_ID, MONTHLY_PLAN_ID, retryInterval, tempPassword } from '../share/constant'
import {
	createDoctor as createDoctorMutation,
	createDoctorStripeCustomerSubscription as createDoctorStripeCustomerSubscriptionMutation,
	createPosibleClient as createPosibleClientMutation,
	updateDoctorStripeCustomerSubscription as updateDoctorStripeCustomerSubscriptionMutation,
} from '../graphql/mutations'
import {
	listDoctors as listDoctorsQuery,
	listPosibleClients as listPosibleClientsQuery,
	listDoctorStripeCustomerSubscriptions as listDoctorStripeCustomerSubscriptionsQuery,
} from '../graphql/queries'
import { Doctor, DoctorStripeCustomerSubscription, PosibleClient, EmailDistributionLists } from '../models';
import { generatePassword, getTrialEndDate } from '../share/utils';

export const UserContext = React.createContext<UserContextType | null>(null)

const ListEmailDistributionListsQuery = `query ListEmailDistributionListss(
	$email: String
	$limit: Int
	$nextToken: String
  ) {
	listEmailDistributionListss(
		filter: {
			email: {
				eq: $email
			}
		}
		limit: $limit
		nextToken: $nextToken
	) {
		items {
			id
			email
			specialty
		}
		nextToken
	}
}`

const CreateEmailDistributionListsMutation = `mutation CreateEmailDistributionLists(
	$input: CreateEmailDistributionListsInput!
	$condition: ModelEmailDistributionListsConditionInput
  ) {
	createEmailDistributionLists(input: $input, condition: $condition) {
	  id
	  email
	  specialty
	}
  }`

const UserProvider: React.FC<IUSerContextProp> = ({ children }) => {
	const { Auth } = Amplify
	const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false)
	const [user, setUser] = React.useState<IUser>({
		id: '',
		name: '',
		email: '',
		phone: '',
	});
	const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
	const [customTempPasswordAssigned, setCustomTempPasswordAssigned] = useState<string>("");

	const handleChangeUser = (
		e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const target = e.target as HTMLInputElement
		setUser((prevUser) => ({ ...prevUser, [target.name]: target.value }))
	}

	const handleChangeEmail = (
		e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const target = e.target as HTMLInputElement
		const regexEmail =
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
		setUser((prevUser) => ({ ...prevUser, email: target.value }))
		if (!regexEmail.test(target.value)) {
			setIsInvalidEmail(true)
		} else {
			setIsInvalidEmail(false)
		}
	}

	const createPosibleClient = async (phoneNumber: string) => {
		const listPossibleClientResponse = await API.graphql<GraphQLQuery<{
			listPosibleClients: { items: PosibleClient[] };
		}>>(graphqlOperation(listPosibleClientsQuery));

		if (!listPossibleClientResponse.data) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			});
			return '';
		}

		const listPosibleClient = listPossibleClientResponse.data.listPosibleClients.items;

		if (
			phoneNumber !== '' &&
			!(listPosibleClient.map((p) => p.phoneNumber).indexOf(phoneNumber) !== -1)
		) {
			const possibleClientData = {
				phoneNumber,
			}

			const possibleClientResponse = await API.graphql<GraphQLQuery<{
				createPosibleClient: PosibleClient;
			}>>(graphqlOperation(createPosibleClientMutation, { input: possibleClientData }));

			if (!possibleClientResponse.data) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al registrar el usuario',
				});
				return null;
			}
		}
	}

	const getDoctorByEmail = async (email: string, retry: number = 1) => {
		const searchData = {
			email: {
				eq: email,
			},
		}
		let response = null;
		let retries = 0;
		while (retries < retry) {
			try {
				const doctorResponse = await API.graphql<GraphQLQuery<{ listDoctors: { items: Doctor[] } }>>(graphqlOperation(listDoctorsQuery, {
					filter: searchData,
				}));

				if (doctorResponse.data && doctorResponse.data?.listDoctors.items.length > 0) {
					response = doctorResponse.data.listDoctors.items[0];
					break;
				}
		
				await new Promise(resolve => setTimeout(resolve, retryInterval));
				retries++;
			} catch (error) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al registrar el usuario',
				});
			}
		}

		return response;
	}

	const createDBSubscription = async (doctor: any, isTransferUser: boolean) => {
		try {
			const customerData = {
				email: user.email,
				metadata: {
					doctorID: doctor.id,
					doctorName: doctor.name,
					doctorReview: doctor.isBeenReview,
					doctorHasTrial: doctor.hasTrialOnCreation,
					doctorPhone: doctor.phone,
				},
			};
			const customer = await createCustomer(customerData)

			const subscriptionData = {
				doctorID: doctor.id,
				stripeCustomerID: customer.id,
				currentPlanID: isTransferUser ? ANNUALLY_PLAN_ID : MONTHLY_PLAN_ID,
				defaultCard: '',
				cancelAtPeriodEnd: false,
			};

			const newSubscriptionResponse = await API.graphql<GraphQLQuery<{
				createDoctorStripeCustomerSubscription: DoctorStripeCustomerSubscription;
			}>>(graphqlOperation(createDoctorStripeCustomerSubscriptionMutation, { input: subscriptionData }));

			if (!newSubscriptionResponse.data) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al registrar el usuario',
				});
				return null;
			}

			const newSubscription = newSubscriptionResponse.data.createDoctorStripeCustomerSubscription;

			return newSubscription;
		} catch (error) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			} as AlertType);
			return null;
		}
	}

	const updateDBSubscription = async (
		subscriptionID: string,
		stripeSubscriptionID: string,
		currentPlanID: number,
		cancelAtPeriodEnd: boolean,
		nextPaymentDate?: string
	) => {
		try {
			const subscriptionData = {
				id: subscriptionID,
				stripeSubscriptionID: stripeSubscriptionID,
				currentPlanID: currentPlanID,
				cancelAtPeriodEnd,
				nextPaymentDate: nextPaymentDate,
			}
			const subscriptionResponse = await API.graphql<GraphQLQuery<{
				updateDoctorStripeCustomerSubscription: DoctorStripeCustomerSubscription;
			}>>(graphqlOperation(updateDoctorStripeCustomerSubscriptionMutation, { input: subscriptionData }));

			if (!subscriptionResponse.data) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al registrar el usuario',
				});
				return null;
			}

			const subscription = subscriptionResponse.data.updateDoctorStripeCustomerSubscription;

			return subscription;
		} catch (error) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			} as AlertType);
			return null;
		}
	};

	const createDoctorHandler = async (doctor: any) => {

		const newDoctorResponse = await API.graphql<GraphQLQuery<{
			createDoctor: Doctor;
		}>>(graphqlOperation(createDoctorMutation, { input: doctor }));

		if (!newDoctorResponse.data) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			});
			return null;
		}

		const newDoctor = newDoctorResponse.data.createDoctor;

		return newDoctor;
	}

	const updateUserPassword = async (password: string) => {
		try {
			
			await Auth.signIn(user.email, tempPassword);

			const us = await Auth.currentAuthenticatedUser();

			await Auth.changePassword(us, tempPassword, password);

			setAlertMessage({
				severity: 'success',
				message: 'Usuario registrado con éxito',
			});

			return "ok"
		} catch (error) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			});
			return '';
		}

	}

	const createDoctor = async (email: string, isTrial = true, useCreditCard = true, needVerification = true): Promise<CreateDoctorResponseMessageType> => {
		const doctorExist = await getDoctorByEmail(email);

		if (doctorExist?.isBeenReview) {
			return CreateDoctorResponseMessageType.VALIDATION_PENDING;
		}

		try {
			await Auth.signIn(email, tempPassword);
			await Auth.signOut();
			return CreateDoctorResponseMessageType.PASSWORD_PENDING;
		} catch (error: any) {
			if (error.code === "UserNotConfirmedException") {
				Auth.resendSignUp(email);
				return CreateDoctorResponseMessageType.SUCCESS;
			}

			if (error.code === "NotAuthorizedException") {
				return CreateDoctorResponseMessageType.EMAIL_ALREADY_EXISTS;
			}
		}


		let doctorData = null;

		if (isTrial) {
			doctorData = {
				email: email,
				name: user.name,
				isBeenReview: false,
				isFirstAddSecretary: true,
				phone: user.phone,
				isAdmin: false,
				hasTrialOnCreation: false,
				daysOfTrial: 14,
				firstTime: true,
			};
		} else {
			doctorData = {
				email: email,
				name: user.name,
				isBeenReview: !useCreditCard,
				isFirstAddSecretary: true,
				phone: user.phone,
				isAdmin: false,
				hasTrialOnCreation: false,
				daysOfTrial: 0,
				firstTime: true,
			};
		}
		const newDoctor = await createDoctorHandler(doctorData);

		if (!newDoctor) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			});
			return CreateDoctorResponseMessageType.ERROR;
		}

		const isTransferUser = !isTrial && !useCreditCard;
		const newSubscription = await createDBSubscription(newDoctor, isTransferUser);

		if (newSubscription && !isTrial && !useCreditCard) {
			const trialEndDate = getTrialEndDate()
			const subscriptionData = {
				customer: newSubscription.stripeCustomerID,
				items: [{ price: process.env.REACT_APP_STRIPE_PLAN_ANUALLY }],
				trial_end: trialEndDate.unix()
			}
			const subscriptionResponse = await createStripeSubscription(subscriptionData);
			await updateDBSubscription(
				newSubscription.id,
				subscriptionResponse.id,
				ANNUALLY_PLAN_ID,
				false,
				trialEndDate.format('DD/MM/YYYY')
			);

		}

		const customTempPassword = !needVerification ? generatePassword() : tempPassword;

		await Auth.signUp({
			username: user.email,
			password: customTempPassword,
			attributes: {
				email: user.email,
				'custom:doctorID': newDoctor.id,
				'custom:isTrial': isTrial.toString(),
				'custom:name': user.name
			},
		})

		if (!needVerification) {
			setCustomTempPasswordAssigned(customTempPassword);
			return CreateDoctorResponseMessageType.NO_VALIDATION_NEEDED;
		}

		return CreateDoctorResponseMessageType.SUCCESS;
	}

	const confirmSignUp = async (username: string, confirmationCode: string) => {

		await Auth.confirmSignUp(username, confirmationCode);
	};

	const resendConfirmationCode = async (email: string) => {
		try {
			await Auth.resendSignUp(email);
			setAlertMessage({
				message: 'El código ha sido enviado a su correo',
				severity: 'success',
			});
		} catch (error) {
			setAlertMessage({
				message: 'Ha ocurrido un error, por favor intente más tarde',
				severity: 'error',
			});
		}
	};

	const getEmailDistributionByEmail = async (email: string) => {
		try {
			const searchData = {
				email: email,
			}
			const emailDistributionListsQueryResponse = await API.graphql<GraphQLQuery<{
				listEmailDistributionListss: {
					items: EmailDistributionLists[]
				}
			}>>(graphqlOperation(ListEmailDistributionListsQuery, searchData));

			if (!emailDistributionListsQueryResponse.errors && emailDistributionListsQueryResponse.data?.listEmailDistributionListss.items.length === 0) {
				return false;
			}

			return true;
		} catch (error) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			});
			return false;
		}

	};

	const createBroadcasterHandler = async (emailDistributionListsData: EmailDistributionLists) => {
		try {
			const emailDistributionListsResponse = await API.graphql<GraphQLQuery<{
				createEmailDistributionLists: EmailDistributionLists;
			}>>(graphqlOperation(CreateEmailDistributionListsMutation, { input: emailDistributionListsData }));
			if (!emailDistributionListsResponse.data) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al registrar el usuario',
				});
				return null;
			}

			const broadcaster = emailDistributionListsResponse.data.createEmailDistributionLists;

			return broadcaster;

		} catch (error) {
			setAlertMessage({
				severity: 'error',
				message: 'Error al registrar el usuario',
			})
		}
	};

	const registerBroadcaster = async (email: string, specialty: string) => {
		const emailDistributionExist = await getEmailDistributionByEmail(email);
		if (!emailDistributionExist) {
			const emailDistributionListData = {
				email: email,
				specialty: specialty,
			} as EmailDistributionLists;

			const newBroadcaster = await createBroadcasterHandler(emailDistributionListData);
			if (!newBroadcaster) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al registrar el usuario',
				});
				return '';
			}
			return 'ok';
		}
		return 'duplicated';
	}

	const userProviderValue = {
		user,
		isInvalidEmail,
		setUser,
		updateUserPassword,
		handleChangeUser,
		handleChangeEmail,
		createPosibleClient,
		createDoctor,
		confirmSignUp,
		alertMessage,
		setAlertMessage,
		resendConfirmationCode,
		registerBroadcaster,
		customTempPasswordAssigned,
	}

	return (
		<UserContext.Provider value={userProviderValue}>
			{children}
		</UserContext.Provider>
	)
}

export default UserProvider


import React, { FC } from 'react';

import PriceCard from '../priceCard';
import { Grid } from '@material-ui/core';

import './PriceSection.style.css';
import DecoratorCard, { Period } from '../priceCard/Decorators/DecoratorCard';

interface props {
    goToRegisterHandler: () => void;
}

const PriceSection: FC<props> = ({
    goToRegisterHandler
}) => {
    return (
        <Grid container className='container'>
            <Grid className='container__head'>
                <h1 className='container__head_title'>¿Listo para empezar? </h1>
                <p className='container__head_subtitle'>Selecciona el plan que se ajuste a tus necesidades</p>
            </Grid>
            <Grid className='container__content' container spacing={3}>
                <DecoratorCard period={Period.YEAR}>
                    <PriceCard
                        title='Anual'
                        tag='Ahorras 2400 pesos'
                        price='RD$ 600/mes'
                        priceDescription='Pagados anualmente'
                        goToRegisterHandler={goToRegisterHandler}
                    >
                        <ul>
                            <li>Migración de datos</li>
                            <li>Soporte técnico</li>
                            <li>Todas las funcionalidades</li>
                        </ul>
                    </PriceCard>
                </DecoratorCard>
                <DecoratorCard period={Period.MONTH}>
                    <PriceCard
                        title='Mensual'
                        price='RD$ 800/mes'
                        priceDescription='Pagados por mes'
                        goToRegisterHandler={goToRegisterHandler}
                    >
                        <ul>
                            <li>Migración de datos</li>
                            <li>Soporte técnico</li>
                            <li>Todas las funcionalidades</li>
                        </ul>
                    </PriceCard>
                </DecoratorCard>
            </Grid>
        </Grid>
    );
};

export default PriceSection;
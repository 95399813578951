import React, { FC } from 'react';
import { ConditionLink, ConditionTypography } from '../formSection/style';
import { CONDITIONS_LINK, TERM_LINK } from '../../share/constant';


const TermsAndConditions:FC<{
    fullWidth?: boolean
}> = ({
    fullWidth = false
}) => {
   return ( <ConditionTypography fullWidth={fullWidth}>
                Al registrarte aceptas nuestros <ConditionLink decoration href={TERM_LINK} target='_blank'>términos</ConditionLink> y <ConditionLink decoration href={CONDITIONS_LINK} target='_blank'>condiciones</ConditionLink>
            </ConditionTypography>
)
};

export default TermsAndConditions;
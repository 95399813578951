import React, { useState } from 'react';
import { NavigationContextType, Tabs } from './type';


export const NavigationContext = React.createContext<NavigationContextType | null>(null);

const NavigationProvider: React.FC = ({ children }) => {
    const [activeTab, setActiveTab] = useState<number>(Tabs.Home);

    return (
        <NavigationContext.Provider value={{
            activeTab,
            setActiveTab
        }}>
            {children}
        </NavigationContext.Provider>
    )
};

export default NavigationProvider;